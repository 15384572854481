import { Box, IconButton, Typography, alpha } from "@mui/material";
import React, { useState } from "react";
import {
  CollapseIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
} from "../../../assets/images";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuTooltip from "../../Global/MenuTooltip";
import { useTheme } from "@mui/material";

const backColor = "#18A0FB";

function MenuItem({
  icon,
  name,
  menuOpen = false,
  subOptions = [],
  setMenuOpen,
  openSubMenu,
  setOpenSubMenu,
  id,
  route,
  handleOpenTooltip,
  handleCloseTooltip,
  openTooltip,
  isMobile,
  isFavorite,
  handleAddFavorite,
  myTheme,
  disable = false
}) {
  // const open = openSubMenu === id;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const open = openSubMenu === id || subOptions.some(subOption => subOption.route === pathname);

  const theme = useTheme();
  console.log(theme.palette.mode)
  const handleOpen = () => {
    if (isMobile) setMenuOpen(false);
    setOpenSubMenu(id);
  };
  const handleClose = () =>
    setOpenSubMenu(openSubMenu === id ? "" : openSubMenu);

  const handleCloseMenu = () => {
    handleClose();
    setMenuOpen((pre) => !pre);
  };

  const toolTipBack =
    myTheme.sidebar.background === "transparent"
      ? backColor
      : myTheme.sidebar.background;

  const MenuItem = ({ subOption }) => {
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);

    return (
      <Link to={subOption.route}
        sx={(theme) => ({
          color: pathname === subOption.route ? theme.sidebar.colorDark : theme.sidebar.color,
          backgroundColor: pathname === subOption.route ? theme.sidebar.background2 : "",
        })}
      >
        <Box
          sx={(theme) => ({

            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "9px 11px",
            borderRadius: "10px",
            cursor: "pointer",
            height: "45px",
            minWidth: "200px",
            backgroundColor: pathname === subOption.route ? theme.palette.background.primary : "",
            color: pathname === subOption.route ? theme.palette.text.white : "",
            "&:hover": {
              backgroundColor:
                toolTipBack !== backColor
                  ? menuOpen
                    ? theme.palette.background.primary
                    : toolTipBack
                  : toolTipBack,
              color:
                toolTipBack !== backColor
                  ? menuOpen
                    ? theme.palette.background.default
                    : theme.palette.background.default
                  : theme.palette.background.default,
            },
          })}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Typography variant="smallBody" className="child-text">
            {t(subOption.name)}
          </Typography>
          <IconButton onClick={(e) =>
            handleAddFavorite(subOption.name, subOption.route, e)
          }>
            <StarRoundedIcon
              color={isFavorite(subOption.name) ? "iconFocus" : "iconColor"}
              sx={(theme) => ({
                color: isHovered ? theme.palette.background.default : pathname === subOption.route ? theme.palette.text.white : "",
              })}
            />
          </IconButton>
        </Box>
      </Link>
    );
  };

  const MenuItems = () => (
    <>
      {subOptions.map((subOption) => (
        <MenuItem subOption={subOption} key={subOption.id} />
      ))}
    </>
  );

  return (
    <Box
      style={{
        borderBottom: route === "/hr-dashboard" ? '1px solid #EEEE' : "",
        borderTop: route === "/hr-dashboard" ? '1px solid #EEEE' : "",
        marginTop: route === "/hr-dashboard" ? '20px' : "",
        pointerEvents: disable ? 'none' : 'auto',

      }}
      onClick={() => {
        if (subOptions.length > 0) {
          if (open) {
            handleClose()
          } else {
            handleOpen()
          }
        }
      }}
    >
      <Box
        sx={{
          ...(menuOpen && { minWidth: "250px" }),
          py: "12px",
          px: "5px",
          borderRadius: "10px",
          color: disable ? theme.palette.grey[400] : pathname === route ? theme.palette.text.white : theme.palette.text.primary,
          backgroundColor: pathname === route ? theme.palette.background.primary : "",
          "&:hover": {
            backgroundColor: theme.palette.background.primary,
            color: theme.palette.text.white,
            img: {
              ...(theme.palette.mode === "light"
                ? {
                  filter: "brightness(0%) invert(100%)",
                }
                : {
                  filter: "brightness(0%) invert(100%)"
                }),
            },
          },

          img: {
            ...(theme.palette.mode !== "light"
              ? {
                filter: disable ? "brightness(100%) invert(100%) grayscale(100%)" : "brightness(0%) invert(100%)",
              }
              : {
                filter: disable ? "brightness(100%) invert(100%) grayscale(100%)" : (pathname === route && "brightness(0%) invert(100%)"),
              }),
          },


          ...(open
            ? menuOpen && {
              "&:hover": {
                backgroundColor: "transparent",
              },
            }
            : {}),
        }}
      >
        <MenuTooltip
          menu={
            subOptions.length > 0 ? (
              <MenuItems />
            ) : (
              <MenuItem
                subOption={{
                  name: t(name),
                  route: route,
                  isFavorite: false,
                }}
              />
            )
          }
          inTooltip={isMobile ? true : !menuOpen && !open}
          isMobile={isMobile}
          open={openTooltip === id}
          handleOpenTooltip={handleOpenTooltip(id)}
          handleCloseTooltip={handleCloseTooltip}

        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              cursor: "pointer",
              img: {
                width: menuOpen ? "20px" : "27px",
                "@media (max-width: 600px)": {
                  width: menuOpen ? "15px" : "20px",
                },
              },
            }}
          >
            <img src={icon} alt="logo" />
            <Box
              sx={{
                flex: 1,
                display: menuOpen ? "flex" : "none",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >

              <Link to={route} style={{ pointerEvents: route !== null ? '' : 'none' }}>
                <Typography variant="body" color="inherit">
                  {t(name)}
                </Typography>
              </Link>

              {subOptions.length > 0 &&
                (open ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                ))}
            </Box>

          </Box>
        </MenuTooltip>
        {menuOpen && open && (
          <Box
            sx={(theme) => ({
              borderLeft: `2px solid ${alpha(theme.palette.primary.main, 0.3)}`,
              paddingLeft: "15px",
              marginLeft: "7px",
            })}
            className="menu-items"
          >
            <MenuItems />
          </Box>
        )}
      </Box>
    </Box>

  );
}

export default MenuItem;
