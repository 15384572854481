import { Box, Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  ExpandLessIcon,
  ExpandMoreIcon,
  LogoutIcon,
  SettingIcon,
  SupportIcon,
  ThemeIcon,
  UserPlaceholder,
} from "../../../../assets/images";
import UserOption from "./UserOption";
import { useNavigate } from "react-router";
import { themeTypes } from "../../../../theme";

const USER_OPTIONS = [
  {
    id: 1,
    name: "Theme",
    icon: ThemeIcon,
    options: ["Default", "Atlantic", "Tokyo"],
  },
  {
    id: 2,
    name: "Settings",
    icon: SettingIcon,
    options: [],
  },
  {
    id: 3,
    name: "Support",
    icon: SupportIcon,
    options: [],
  },
  {
    id: 4,
    name: "Logout",
    icon: LogoutIcon,
    options: [],
  },
];

export const UserImage = ({ userPicture }) => {
  return (
    <img
      src={userPicture}
      alt="logo"
      style={{
        width: "40px",
        borderRadius: "50%",
        border: "1px solid #092C4C",
        aspectRatio: "1/1",
        objectFit: "cover",
      }}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = UserPlaceholder;
      }}
    />
  );
};

function UserInfo() {
  const [anchorEl, setAnchorEl] = useState(null);

  const userName = sessionStorage.getItem("fullname");
  const empId = sessionStorage.getItem("empId_key");
  const email = sessionStorage.getItem("email_key");

  let userPicture =
    process.env.REACT_APP_API_PROFILE_SERVICE_URL +
    "/api/Employee/GetProfilePictureFileStream?EmployeeDetailId=" +
    empId +
    "&email=" +
    email
      ? process.env.REACT_APP_API_PROFILE_SERVICE_URL +
        "/api/Employee/GetProfilePictureFileStream?EmployeeDetailId=" +
        empId +
        "&email=" +
        email
      : UserPlaceholder;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isTablet = window.innerWidth <= 1024;
  const navigate = useNavigate();

  const onLogout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };

  return (
    <Box
      sx={{
        mt: isTablet ? "auto" : "0px",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        onClick={handleClick}
      >
        <UserImage userPicture={userPicture} />
        <Typography variant="smallBody">{userName}</Typography>
        <ExpandMoreIcon sx={{ width: "15px" }} />
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: "177px",
            maxHeight: "270px",
            padding: "11px 20px",
            // hide scxrollbar
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            borderBottom: ".5px solid #092C4C",
            pb: "10px",
            mb: "5px",
          }}
        >
          <UserImage userPicture={userPicture} />
          <Typography variant="smallBody">{userName}</Typography>
        </Stack>
        <UserOption
          icon={USER_OPTIONS[0].icon}
          name={USER_OPTIONS[0].name}
          options={Object.keys(themeTypes)}
        />
        {/* <UserOption
          icon={USER_OPTIONS[1].icon}
          name={USER_OPTIONS[1].name}
          options={USER_OPTIONS[1].options}
        />
        <UserOption
          icon={USER_OPTIONS[2].icon}
          name={USER_OPTIONS[2].name}
          options={USER_OPTIONS[2].options}
        /> */}

        <UserOption
          icon={USER_OPTIONS[3].icon}
          name={USER_OPTIONS[3].name}
          options={USER_OPTIONS[3].options}
          onClick={onLogout}
        />
      </Popover>
    </Box>
  );
}

export default UserInfo;
