import { Box, Typography, Stack } from "@mui/material";
import React, { useEffect, useState, useRef, useContext } from "react";
import Center from "../../components/Box/Center";
import { customColor } from "../../theme/customColor";
import { useTranslation } from "react-i18next";
import jwtInterceptor from "../../services/interceptors";
import { useNavigate } from "react-router-dom";
import RotateRightTwoToneIcon from '@mui/icons-material/RotateRightTwoTone';
import useStyles from "./IconStyle";

function CertificateRenewal(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    if (props.CERTIFICATIONS.length === 0) {
        return (
            <Box>
                <Typography variant="extraSmallBody">No certificate renewal pending</Typography>
            </Box>
        );
    }
    return (
        <>
            {props.CERTIFICATIONS.map((item) => (
                <>
                    <RotateRightTwoToneIcon className={classes.IconStyle} />
                    <Stack mr={"auto"}>
                        <Typography className={classes.TypographyFontSize} sx={{ fontWeight: 700, fontSize: 14 }}>
                            {item.name}
                        </Typography>
                        <Typography color={(theme) => theme.palette.action.disabled} sx={{ fontWeight: 500, fontSize: 12 }}>
                            {item.renewDate}
                        </Typography>
                    </Stack>
                </>
            ))}
        </>
    );
}

function MonthCertificateRenewal(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    if (props.CERTIFICATIONS.length === 0) {
        return (
            <Box>
                <Typography variant="extraSmallBody">No certificate renewal pending</Typography>
            </Box>
        );
    }
    return (
        <>
            {props.CERTIFICATIONS.map((item) => (
                <>
                    <RotateRightTwoToneIcon />
                    <Stack mr={"auto"}>
                        <Typography className={classes.TypographyFontSize} sx={{ fontWeight: 700, fontSize: 14 }}>
                            {item.name}
                        </Typography>
                        <Typography color={(theme) => theme.palette.action.disabled} sx={{ fontWeight: 500, fontSize: 12 }}>
                            {item.renewDate}
                        </Typography>
                    </Stack>
                </>
            ))}
        </>
    );
}
function RenewalCertificate() {
    const { t } = useTranslation();
    const initialized = useRef(false);
    const navigate = useNavigate();
    const bearerToken = sessionStorage.getItem("token_key");
    const [monthCertificateRenewal, setMonthcertificateRenewal] = useState([]);
    const [weekCertificateRenewal, setWeekcertificateRenewal] = useState([]);

    const getCertificateRenewalDetails = async () => {
        jwtInterceptor
            .get(
                "api/EmployeeSkill/GetSkillRenewalDashboardByEmployeeDetailId?EmployeeDetailId=" + 77
            )
            .then((response) => {
                let renewalPending30DaysList = [];
                let renewalWeekPendingList = [];
                for (var x of response.data.renewalPending30DaysList) {
                    const renewalDate = new Date(x.renewalDate).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                    });
                    let item = {
                        id: x.employeeSkillId,
                        name: x.skill,
                        renewDate: renewalDate
                    };
                    renewalPending30DaysList.push(item);
                }
                const monthPendingData = <MonthCertificateRenewal CERTIFICATIONS={renewalPending30DaysList} />;
                setMonthcertificateRenewal(monthPendingData);
                for (var x of response.data.renewalPending7DaysList) {
                    const weekRenewalDate = new Date(x.renewalDate).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                    });
                    let item = {
                        id: x.employeeSkillId,
                        name: x.skill,
                        renewDate: weekRenewalDate
                    };
                    renewalWeekPendingList.push(item);
                }
                const weekPendingData = <CertificateRenewal CERTIFICATIONS={renewalWeekPendingList} />;
                setWeekcertificateRenewal(weekPendingData);
            });
    };
    useEffect(() => {
        if (!initialized.current) {
            if (bearerToken) {
                initialized.current = true;
                getCertificateRenewalDetails();
            } else {
                window.location.href = "/login";
            }
        }
    }, []);

    return (
        <Box
            flex={1}
            boxShadow={"0px 3.5px 5.5px 0px #91919114"}
            p={2.5}
            borderRadius={3}
        >
            <Typography variant="h7" fontWeight={600}>
                Certificate renewal
            </Typography>

            <Typography style={{ fontSize: '0.6rem', paddingRight: '250px' }}
                color={(theme) => theme.palette.action.disabled}
                sx={{ fontWeight: 600, mb: 2, mt: 1 }}
            >
                Less than a week
            </Typography>
            <Center gap={2}>
                {weekCertificateRenewal}
            </Center>
            <Typography style={{ fontSize: '0.6rem', paddingRight: '220px' }}
               color={(theme) => theme.palette.action.disabled}
                sx={{ fontWeight: 600, my: 2 }}
            >
                1 Month
            </Typography>
            <Center gap={2}>
                {monthCertificateRenewal}
            </Center>
        </Box>

    );
};
export default RenewalCertificate;