import React from 'react';

const FemaleIcon = () => {
  return (
    <svg
      width='14'
      height='26'
      viewBox='0 0 14 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.00003 0C8.79494 0 10.25 1.45509 10.25 3.25C10.25 5.04491 8.79494 6.5 7.00003 6.5C5.20511 6.5 3.75003 5.04491 3.75003 3.25C3.75003 1.45509 5.20511 0 7.00003 0ZM13.0574 17.9857L10.6199 8.23565C10.5539 7.97197 10.4018 7.7379 10.1875 7.57063C9.97332 7.40336 9.70932 7.3125 9.43753 7.3125H8.86065C7.70736 7.84281 6.34241 7.86571 5.1394 7.3125H4.56253C4.29073 7.3125 4.02674 7.40336 3.81251 7.57063C3.59828 7.7379 3.44611 7.97197 3.38019 8.23565L0.942686 17.9857C0.750631 18.7538 1.33198 19.5 2.12503 19.5H4.96878V24.7812C4.96878 25.4544 5.51442 26 6.18753 26H7.81253C8.48563 26 9.03128 25.4544 9.03128 24.7812V19.5H11.875C12.6668 19.5 13.2497 18.755 13.0574 17.9857Z'
        fill='#964CF5'
      />
    </svg>
  );
};

export default FemaleIcon;