import React, { useState } from "react";
import BaseModal from "../Global/Modal";
import EnhancedTable from "../Global/Table";
import CircularIcon from "../Global/CircularIcon";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Grid,
  Switch,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DeleteModal from "../Global/DeleteModal";
import { useTranslation } from "react-i18next";
import jwtInterceoptor from "../../services/interceptors";
import { useSnackbar } from "../Global/WithSnackbar";

const headCells = [
  {
    id: "title",
    label: "Title",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "expiryDate",
    label: "Expiration date",
  },

  {
    id: "visibleToUser",
    label: "Visible to user",
  },
  {
    id: "Action",
    label: "Action",
  },
];

function createData(title, description, expiryDate, visible, Action, t) {
  return {
    title,
    description,
    expiryDate,
    visible: visible ? t("Yes") : t("No"),
    Action,
  };
}

function CellAction({ onEdit, onDelete }) {
  return (
    <Box className="action-icon-rounded">
      <CircularIcon
        icon={<BorderColorIcon />}
        color="#18A0FB"
        onClick={onEdit}
        sx={{
          width: "40px",
          height: "40px",
        }}
      />
      <CircularIcon
        icon={<DeleteIcon />}
        color="#DF6F79"
        onClick={onDelete}
        sx={{
          width: "40px",
          height: "40px",
        }}
      />
    </Box>
  );
}

function AnnouncementsList() {
  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [setselected, setSetselected] = useState(null);
  const { showMessage } = useSnackbar();

  const { t, i18n } = useTranslation();

  const getAllAnnouncements = () => {
    jwtInterceoptor
      .get("api/Anouncement/GetAllAnnouncement")
      .then((response) => {
        setRowData(response.data);
      })
      .catch((error) => {
        showMessage(error.message, "error");
      });
  };

  const createNewAnnouncement = (data) => {
    jwtInterceoptor
      .post("api/Anouncement/AddAnouncement", {
        title: data.title,
        description: data.description,
        expiryDate: data.expiryDate,
        isVisibleToUser: data.isVisibleToUser || false,
      })
      .then((response) => {
        getAllAnnouncements();
        showMessage("Announcement created successfully", "success");
      })
      .catch((error) => {
        showMessage(error.message, "error");
      });
  };

  const updateAnnouncement = (data) => {
    jwtInterceoptor
      .post("api/Anouncement/UpdateAnouncement", {
        anouncementId: data.anouncementId,
        title: data.title,
        description: data.description,
        expiryDate: data.expiryDate,
        startDate: data.startDate,
        isVisibleToUser: data.isVisibleToUser,
      })
      .then((response) => {
        getAllAnnouncements();
        showMessage("Announcement updated successfully", "success");
      })
      .catch((error) => {
        showMessage(error.message, "error");
      });
  };

  const deleteAnnouncement = (id) => {
    jwtInterceoptor
      .delete(`api/Anouncement/DeleteAnouncement?AnouncementId=${id}`)
      .then((response) => {
        getAllAnnouncements();
        showMessage("Announcement deleted successfully", "success");
        setDeleteModal(false);
      })
      .catch((error) => {
        showMessage(error.message, "error");
      });
  };

  React.useEffect(() => {
    getAllAnnouncements();
  }, []);

  const onEdit = (row) => {
    setAddEditModal(true);
    setSetselected(row);
  };

  const onDelete = (row) => {
    setDeleteModal(true);
    setSetselected(row);
  };

  const rows = rowData.map((row) => {
    return createData(
      row.title,
      row.description,
      dayjs(row.expiryDate).format("DD/MM/YYYY"),
      row.isVisibleToUser,
      <CellAction onEdit={() => onEdit(row)} onDelete={() => onDelete(row)} />,
      t
    );
  });

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.common.border}`,
        borderRadius: "20px",
      })}
    >
      <EnhancedTable
        head={headCells}
        rows={rows}
        isAddable={true}
        title={t("Announcement list")}
        onAddClick={() => setAddEditModal(true)}
      />

      <BaseModal
        open={addEditModal}
        title="Announcements"
        handleClose={() => {
          setAddEditModal(false);
          setSetselected(null);
        }}
        onSave={async () => {
          setAddEditModal(false);
          if (setselected?.anouncementId && setselected?.anouncementId !== 0) {
            const index = rowData.findIndex((row) => row.id === setselected.id);
            const temp = [...rowData];
            temp[index] = setselected;
            setRowData(temp);
            await updateAnnouncement(setselected);
          } else {
            await createNewAnnouncement(setselected);
          }
          setSetselected(null);
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography variant="SmallBody">{t("Title")}</Typography>
            <TextField
              variant="outlined"
              name="title"
              placeholder={t("Enter Title")}
              value={setselected?.title}
              onChange={(e) => {
                setSetselected({
                  ...setselected,
                  title: e.target.value,
                });
              }}
            />
          </Grid>{" "}
          <Grid item md={6} xs={12}>
            <Typography variant="SmallBody">{t("Expiration date")}</Typography>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={i18n.language}
            >
              <DatePicker
                name="expiryDate"
                defaultValue={dayjs(new Date().toLocaleDateString("en-GB"))}
                value={dayjs(setselected?.expiryDate)}
                format="DD/MM/YYYY"
                onChange={(newValue) => {
                  setSetselected({
                    ...setselected,
                    expiryDate: newValue.toDate(),
                  });
                }}
              />
            </LocalizationProvider>
          </Grid>{" "}
          <Grid item xs={12}>
            <Typography variant="SmallBody">{t("Description")}</Typography>
            <TextField
              variant="outlined"
              name="description"
              placeholder={t("Enter announcements description")}
              multiline
              rows={4}
              value={setselected?.description}
              onChange={(e) => {
                setSetselected({
                  ...setselected,
                  description: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="SmallBody">{t("Visible to users")}</Typography>
            <Switch
              checked={setselected?.isVisibleToUser}
              onChange={(e) => {
                setSetselected({
                  ...setselected,
                  isVisibleToUser: e.target.checked,
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </Grid>
      </BaseModal>
      <DeleteModal
        open={deleteModal}
        message="Would you like to delete the selected announcement ?"
        title="Delete Announcement"
        onCancel={() => setDeleteModal(false)}
        onConfirm={() => deleteAnnouncement(setselected?.anouncementId)}
      />
    </Box>
  );
}

export default AnnouncementsList;
