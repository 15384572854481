import React, { useEffect, useState, useRef } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { StarIcon } from "../../../../assets/images";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { useSnackbar } from "../../../Global/WithSnackbar";
import { Link, useNavigate } from "react-router-dom";
import jwtInterceptor from "../../../../services/interceptors";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "../../../Global/DeleteModal";
import { useTranslation } from "react-i18next";

export default function BasicPopover({ isTablet, favs, getFavsData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const initialized = useRef(false);
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const [deleteFav, setDeleteFav] = useState({
    open: false,
    id: null,
  });

  const { t } = useTranslation();

  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  const onFavDelete = async () => {
    let url =
      "api/FavouriteLink/RemoveFromFavourite?FavouriteLinkId=" + deleteFav.id;
    jwtInterceptor.post(url).then((response) => {
      showMessage(response.data, "success");
      getFavsData();
    });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function onFavConfirmationDelete() {
    setDeleteFav({ open: false, id: null });
    onFavDelete();
  }

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
      } else {
        window.location.href = "/login";
      }
    }
  }, []);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div
      style={{
        marginTop: "7px",
      }}
    >
      {isTablet ? (
        <Button onClick={handleClick}>{t("Favorites")}</Button>
      ) : (
        <StarIcon onClick={handleClick} color="info" />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            width: "264px",
            height: "299px",
          },
        }}
      >
        <Typography
          sx={{
            p: 2,
            borderBottom: `.5px solid #18a0fb64`,
            paddingBlock: "12px",
          }}
        >
          {t("Favorites")}
        </Typography>
        {favs.map((option) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link key={option.id} to={option.URL}>
              <Box
                sx={{
                  padding: "10px",
                  width: "95%",
                  "&:hover": {
                    backgroundColor: "#F5F5F5",
                    borderRadius: "10px",
                    cursor: "pointer",
                  },
                }}
                display="flex"
                alignItems="center"
                gap="10px"
              >
                <img src={option.icon} alt="icon" />
                <Typography variant="body">{t(option.name)}</Typography>
              </Box>
            </Link>
            <IconButton
              onClick={() => setDeleteFav({ open: true, id: option.id })}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Stack>
        ))}
        {favs.length === 0 && (
          <Box
            sx={{
              padding: "15px",
              width: "95%",
            }}
            display="flex"
            alignItems="center"
            gap="10px"
          >
            <Typography variant="body">{t("No favorites yet")}</Typography>
          </Box>
        )}
      </Popover>
      <DeleteModal
        message={"Are you sure you want to delete this favorite?"}
        title={"Delete Favorite"}
        onCancel={() => setDeleteFav({ open: false, id: null })}
        onConfirm={() => onFavConfirmationDelete()}
        open={deleteFav.open}
      />
    </div>
  );
}
