import React from "react";
import BaseModal from "../../Global/Modal";
import {
  Checkbox,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import BasicTabs from "../../Global/BasicTabs";
import { CalendarIcon } from "@mui/x-date-pickers";
import { Box } from "@mui/system";
import EnhancedTable from "../../Global/Table";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";

const Employee = (data, setData) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: "620px",
      }}
    >
      <Grid item xs={12} md={6}>
        <Typography variant="SmallBody">{t("First Name")}</Typography>
        <TextField variant="outlined" placeholder={t("Enter First Name")} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="SmallBody">{t("Last Name")}</Typography>
        <TextField variant="outlined" placeholder={t("Enter Last Name")} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="SmallBody">{t("Employee ID")}</Typography>
        <TextField variant="outlined" placeholder={t("Enter Employee ID")} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="SmallBody">{t("Line Manager")}</Typography>
        <TextField variant="outlined" placeholder={t("Enter Line Manager")} />
      </Grid>
      {/* now three block in next row */}
      <Grid item xs={12} md={4} direction="column">
        <Typography variant="SmallBody">{t("Status")}</Typography>
        <Stack direction="row" spacing={2} mt={2} alignItems="center">
          <Switch />
          <Typography variant="Small">{t("Active")}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="SmallBody">{t("Department")}</Typography>
        <Box mt={2}>
          <Typography variant="Small">{t("Finance")}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="SmallBody">{t("Starting date")}</Typography>
        <Stack direction="row" spacing={1} mt={2}>
          <CalendarIcon color="primary" />
          <Typography variant="Small">12/12/2021</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="SmallBody">{t("Comments")}</Typography>
        <TextField
          variant="outlined"
          placeholder={t("Enter comments")}
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

const headCells = [
  {
    id: "equipment",
    label: "Equipment",
  },
  {
    id: "brand",
    label: "Brand",
  },
  {
    id: "model",
    label: "Model",
  },
  {
    id: "registrationNumber",
    label: "Registration Number",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "comments",
    label: "Comments",
  },
];

function createData(
  equipment,
  brand,
  model,
  registrationNumber,
  status,
  comments
) {
  return {
    equipment,
    brand,
    model,
    registrationNumber,
    status,
    comments,
  };
}

const states = [
  {
    label: "Granted",
    color: "success",
  },
  {
    label: "Returned",
    color: "warning",
  },
];
function Status({ status }) {
  const [currentState, setCurrentState] = React.useState(status);

  return (
    <Stack direction="row" gap="5px" alignItems="center">
      {states.map((state, i) => (
        <Checkbox
          key={i}
          checked={currentState === state.label}
          color={state.color}
          onChange={() => setCurrentState(state.label)}
        />
      ))}
    </Stack>
  );
}

const rows = [
  createData(
    "Laptop",
    "HP",
    "HP 250 G7",
    "HP 250 G7",
    "09 Sep, 2021",
    <Status status="Granted" />,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  ),
  createData(
    "Car",
    "Toyota",
    "Toyota Corolla",
    "Toyota Corolla",
    "09 Sep, 2021",
    <Status status="Returned" />,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  ),
  createData(
    "Car",
    "Toyota",
    "Toyota Corolla",
    "Toyota Corolla",
    "09 Sep, 2021",
    <Status status="N/A" />,
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  ),
];

const Assets = () => {
  const { t } = useTranslation();
  return (
    <>
      <EnhancedTable head={headCells} rows={rows} hidePagination />;
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        {states.map((state, i) => (
          <Stack direction="row" gap="5px" alignItems="center" key={i}>
            <CircleIcon fontSize="12px" color={state.color} />
            <Typography variant="Small">{t(state.label)}</Typography>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

function AssetsModal({ open, handleClose, title, onSave, data, setData }) {
  const EmployeePanel = Employee(data, setData);
  const AssetsPanel = Assets();

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title={title}
      onSave={onSave}
      sx={{
        maxWidth: "fit-content",
      }}
    >
      <BasicTabs
        tabs={["Employee", "Assets list"]}
        tabPanels={[EmployeePanel, AssetsPanel]}
      />
    </BaseModal>
  );
}

export default AssetsModal;
