import { Outlet, useLocation } from "react-router";
import React, { useEffect, useState } from "react";
import Topbar from "../Navigation/Topbar/Topbar";
import Sidebar from "../Navigation/Sidebar/Sidebar";
import { Box } from "@mui/material";
import { MENU_FULL_OPTIONS, MENU_NEW_USER } from "../../data";
import jwtInterceoptor from "../../services/interceptors";
import { LeaveManagementIcon, DashboardBackground } from "../../assets/images";

function Layout() {
  const location = useLocation();

  const barrerToken = sessionStorage.getItem("token_key");
  const allowedRoutes = ["/login", "/forget-password","/authentication"];

  if (!allowedRoutes.includes(location.pathname)) {
    if (!barrerToken) {
      window.location.href = "/login";
    }
  }
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  const [favs, setFavs] = useState([]);
  const getFavsData = async () => {
    jwtInterceoptor
      .get(
        "api/FavouriteLink/GetFavouriteLinksByEmployeedetaiLId?EmployeeDetailId=" +
        empId
      )
      .then((response) => {
        let allFavs = [];
        for (var x of response.data) {
          let item = {
            id: x.favouriteLinkId,
            name: x.title,
            URL: x.favouriteLinkurl,
            icon: LeaveManagementIcon,
          };
          allFavs.push(item);
        }
        setFavs(allFavs);
      });
  };

  useEffect(() => {
    if (bearerToken) {
      getFavsData();
    }
  }, []);

  const refetchFavs = () => {
    getFavsData();
  };

  const menuItems = location.pathname === "/" ? MENU_NEW_USER : MENU_FULL_OPTIONS;

  return allowedRoutes.includes(location.pathname) ? (
    <Outlet />
  ) : (
    <Box
      sx={(theme) => ({
        // backgroundColor: theme.palette.background.foreground,
        backgroundColor: theme.palette.background.foreground,
        background: `url(${DashboardBackground})`,
        backgroundSize: "cover",
        height: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
        display: "flex",
      })}
    >
      <Sidebar menuItems={menuItems} favs={favs} refetchFavs={refetchFavs} />
      <Box
        sx={{
          width: location.pathname === "/" ? "100%" : "90%",
          // width: location.pathname === "/presentations" ? "94%" : "100%",
          flexDirection: "column",
          backgroundColor: (theme) => theme.palette.background.foreground,
          ...(location.pathname === "/presentations" && {
            width: "94%",
            "@media (max-width: 1260px)": {
              pr: "20px",
            },
          }),
          flex: 1
        }}
        display="flex"
      >
        <Topbar favs={favs} refetchFavs={refetchFavs} />
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default,
            // height: "calc(98vh - 75px)",
            overflowY: "auto",
            borderRadius: "10px 10px 10px 10px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            margin: 1,
            flex:1
          })}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
