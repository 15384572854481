import React, { useReducer, useEffect, useState, useRef } from "react";
import EnhancedTable from "../Global/Table";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { Circle } from "@mui/icons-material";
import BaseModal from "../Global/Modal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DeleteModal from "../Global/DeleteModal";
import jwtInterceptor from "../../services/interceptors";
import NewAssetModal from "./EmployeeInformation/NewAssetModal";
import ViewDocModal from "./EmployeeInformation/ViewDocModal";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../Global/WithSnackbar";
import { errorHelperText, validateEmployeeInfo, validateEmployeeResignationInfo } from "../../utils/validation";
import { useTranslation } from "react-i18next";
import CountrySelect from "../Global/CountryDropdown";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import Select from "../Global/Select";
import PropTypes from 'prop-types';

const headCells = [
  {
    id: "employeeId",
    label: "Employee ID",
  },
  {
    id: "fullName",
    label: "Full Name",
  },
  {
    id: "department",
    label: "Department",
  },
  {
    id: "lineManager",
    label: "Line Manager",
  },
  {
    id: "startingDate",
    label: "Starting Date",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "Action",
    label: "Action",
  },
];

function createData(
  employeeId,
  fullName,
  department,
  lineManager,
  startingDate,
  status,
  Action
) {
  return {
    employeeId,
    fullName,
    department,
    lineManager,
    startingDate,
    status,
    Action,
  };
}

function Status({ status }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        gap: "5px",
        alignItems: "center",
      }}
    >
      <Circle
        color={status === true || status === "Active" ? "success" : "error"}
        fontSize="5px"
      />
      <Typography variant="smallBodyBold">{t(status)}</Typography>
    </Box>
  );
}

function CellAction({ onDelete, onEdit, onDocument, onAsset }) {
  const { t } = useTranslation();
  return (
    <Box className="action-icon-rounded">
      <Tooltip title={t("Edit")} placement="top">
        <Button
          sx={{
            backgroundColor: alpha("#27AE60", 0.1),

            svg: {
              fill: "#27AE60",
            },
          }}
        >
          <BorderColorIcon onClick={onEdit} />
        </Button>
      </Tooltip>
      <Tooltip title={t("Delete")} placement="top">
        <Button
          sx={{
            backgroundColor: alpha("#DF6F79", 0.1),

            svg: {
              fill: "#DF6F79",
            },
          }}
        >
          <DeleteIcon onClick={onDelete} />
        </Button>
      </Tooltip>
      <Tooltip title={t("View Documents")} placement="top">
        <Button
          sx={{
            backgroundColor: alpha("#964CF5", 0.1),

            svg: {
              fill: "#964CF5",
            },
          }}
        >
          <FileOpenIcon BorderColorIcon onClick={onDocument} />
        </Button>
      </Tooltip>
      <Tooltip title={t("Assign Asset")} placement="top">
        <Button
          sx={{
            backgroundColor: alpha("#18A0FB", 0.1),

            svg: {
              fill: "#18A0FB",
            },
          }}
        >
          <SmartphoneIcon onClick={onAsset} />
        </Button>
      </Tooltip>
    </Box>
  );
}
const initialState = {
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfJoining: new Date(),
  contractType: "",
  lineManager: 0,
  email: "",
  department: "",
  gender: "",
  costCenter: "",
  roleNames: [],
  designation: "",
  errors: [],
  status: "",
  country: "",
  resignationDate: null,
  lastWorkingDate: null,
};


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function EmployeeSkillsTable() {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const initialized = useRef(false);

  const [AssetsModal, setAssetsModal] = useState(false);
  const [newDocModal, setNewDocModal] = useState(false);
  const [viewDocsModal, setViewDocsModal] = useState(false);
  const [formData, setformdatastate] = useState(null);

  const tblRows = [];

  const [employeeData, setemployeeDataState] = useState([]);
  const [managersListData, setmangersListDataState] = useState([]);
  const [contractsListData, setcontractsListDataState] = useState([]);

  const [departmentsListData, setdepartmentsListDataState] = useState([]);
  const [gendersListData, setgendersListDataState] = useState([]);
  console.log("G ENDER", gendersListData)
  const [rolesListData, setrolesListDataState] = useState([]);
  const [costCenterData, setcostCenterDataState] = useState([]);
  const [id, setIdState] = useState(0);

  const [activeEmployeId, setActiveEmployeId] = useState(0);
  const [btnType, setbtnTypeState] = useState("Add");
  const [modelType, setmodelTypeState] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [selectedId, setselectedId] = useState(null);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange1 = (event, newValue) => {
    setActiveTab(newValue)
  };

  const bearerToken = sessionStorage.getItem("token_key");

  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case "costcenter":
        return { ...prevState, costCenter: action.value };
      case "firstname":
        return { ...prevState, firstName: action.value };
      case "lastname":
        return { ...prevState, lastName: action.value };
      case "status":
        return { ...prevState, status: action.value };
      case "country":
        return { ...prevState, country: action.value };
      case "middlename":
        return {
          ...prevState,
          middleName: action.value,
        };
      case "joiningdate":
        return { ...prevState, dateOfJoining: action.value };
      case "contracttype":
        return {
          ...prevState,
          contractType: action.value,
        };
      case "lineManager":
        return {
          ...prevState,
          lineManager: action.value,
        };
      case "email":
        return {
          ...prevState,
          email: action.value,
        };
      case "department":
        let newArray = departmentsListData.filter(function (el) {
          return el.department == action.value;
        });
        return {
          ...prevState,
          department: action.value,
          costCenter: newArray.length > 0 ? newArray[0].costCenter : "",
        };
      case "roleType":
        return {
          ...prevState,
          roleNames: action.value,
        };
      case "designation":
        return {
          ...prevState,
          designation: action.value,
        };
      case "gender":
        return {
          ...prevState,
          gender: action.value,
        };
      case "errors":
        return {
          ...prevState,
          errors: action.value,
        };
      case "resignationDate":
        return { ...prevState, resignationDate: action.value };
      case "lastWorkingDate":
        return { ...prevState, lastWorkingDate: action.value };
      case "reset":
        return initialState;
      default:
        throw new Error();
    }
  }, initialState);

  console.log("===>", state)

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        GetEmployeeListData();
        GetMangersListData();
        GetRolesListData();
        GetDepartmentsListData();
        GetContractsListData();
        GetGenderData();
      } else {
        navigate("/login");
      }
    }
  }, []);

  const { t, i18n } = useTranslation();

  const GetEmployeeListData = async () => {
    setLoading(true);

    jwtInterceptor
      .get("api/HrEmployeeDetail/GetEmployeeDetailsList")
      .then((response) => {
        if (response.status !== 200) {
          showMessage(response.statusText, "error");
          navigate("/login", { replace: true });
        }

        for (var x of response.data) {
          let eId = x.employeeDetailId;
          let item = x;
          tblRows.push(
            createData(
              x.employeeId,
              x.fullName,
              x.department,
              x.lineManagerName,
              new Date(x.dateOfJoining).toLocaleDateString("en-GB"),
              <Status status={x.status} />,
              <CellAction
                onEdit={() => {
                  console.log(" TEST EDIT", item);
                  onEdit("Edit", item);
                }}
                onDelete={() => onDelete(eId)}
                onDocument={() => onDocumentUploadModel(eId)}
                onAsset={() => onAssetAssignModel(eId)}
              />
            )
          );
        }

        setemployeeDataState(tblRows);
      })
      .finally(() => setLoading(false));
  };
  //

  const GetMangersListData = async () => {
    let url = "api/HrEmployeeDetail/GetManagerList";
    let mangers = [];
    jwtInterceptor.get(url).then((response) => {
      // if (response.data.Success) {
      for (var x of response.data) {
        let item = {
          managerId: x.employeeDetailId,
          managerName: x.userName,
        };
        mangers.push(item);
      }

      setmangersListDataState(mangers);
      //}
    });
  };
  const GetGenderData = async () => {
    let url = "api/GenderMaster/GetAllGenderMasters";
    let genderList = [];
    jwtInterceptor.get(url).then((response) => {
      setgendersListDataState(response.data);
    });
  };
  const GetContractsListData = async () => {
    let url = "api/ContractTypeMasters/GetAllContractType";
    let contracts = [];
    jwtInterceptor.get(url).then((response) => {
      for (var x of response.data) {
        let item = {
          contractTypeId: x.contractTypeId,
          contractType: x.contractType,
        };
        contracts.push(item);
      }

      setcontractsListDataState(contracts);
    });
  };

  const GetDepartmentsListData = async () => {
    let url = "api/DepartmentMaster/GetAllDepartmentMasterList";
    // let response = await hrService.GetmanagersListDataRequest(url, bearerToken);
    let departments = [];
    jwtInterceptor.get(url).then((response) => {
      for (var x of response.data) {
        let item = {
          departmentId: x.departmentId,
          department: x.department,
          costCenter: x.costCenter,
        };
        departments.push(item);
      }

      setdepartmentsListDataState(response.data);
    });
  };

  const GetRolesListData = async () => {
    let url = "api/EmployeeRoles/GetRoles";
    //let response = await hrService.GetRolesListDataRequest(url, bearerToken);
    let roles = [];

    jwtInterceptor.get(url).then((response) => {
      for (var x of response.data) {
        let item = {
          roleType: x.name,
        };
        roles.push(item);
      }
      setrolesListDataState(roles);
    });
  };

  const GetCostCenterData = async () => {
    let url = "api/ContractTypeMasters/GetAllContractType";
    //let response = await hrService.GetRolesListDataRequest(url, bearerToken);
    let costCenters = [];

    jwtInterceptor.get(url).then((response) => {
      for (var x of response.data) {
        let item = {
          costCenterType: x.contractType,
          costCenterId: x.contractTypeId,
        };
        costCenters.push(item);
      }
      setcostCenterDataState(costCenters);
      console.log(costCenters)
    });
  };

  const createNewemployee = async () => {
    const newEmployeeItem = {
      employeeId: state.employeeId,
      firstName: state.firstName,
      middleName: state.middleName,
      lastName: state.lastName,
      dateOfJoining: state.dateOfJoining,
      contractType: state.contractType,
      lineManager: state.lineManager,
      email: state.email,
      department: state.department,
      costCenter: state.costCenter,
      gender: state.gender,
      status: state.status,
      country: state.country,
      roleNames:
        state.roleNames.length > 1
          ? state.roleNames.join(",")
          : state.roleNames[0],
      designation: state.designation,
    };
    let url = "api/HrEmployeeDetail/AddEmployeeDetails";

    /*let response = await hrService.createNewEmployeeRequest(
      url,
      newEmployeeItem,
      bearerToken
    );*/

    jwtInterceptor.post(url, newEmployeeItem).then((response) => {
      showMessage(response.data, "success");
      GetEmployeeListData();
    });

    setOpen((pre) => !pre);

    // handleClose()
  };

  const updatEmployee = async () => {
    const updateEmployeeItem = {
      employeeDetailId: id,
      employeeId: state.employeeId,
      firstName: state.firstName,
      middleName: state.middleName,
      lastName: state.lastName,
      dateOfJoining: state.dateOfJoining,
      contractType: state.contractType,
      lineManager: state.lineManager,
      email: state.email,
      department: state.department,
      costCenter: state.costCenter,
      gender: state.gender,
      status: state.status,
      country: state.country,
      roleNames:
        state.roleNames.length > 1
          ? state.roleNames.join(",")
          : state.roleNames[0],
      designation: state.designation,
    };

    console.log(" TEST UPDADATE", updateEmployeeItem);

    let url = "api/HrEmployeeDetail/UpdateEmployeeDetails";
    jwtInterceptor.post(url, updateEmployeeItem).then(async (response) => {
      await GetEmployeeListData();
      showMessage(response.data, "success");
    }).catch(error => {
      console.error("Update failed:", error);
    });
    setOpen((pre) => !pre);
    dispatch({ type: "reset" });
  };


  const updatEmployeeResignation = async () => {
    const updateEmployeeItem = {
      employeeDetailId: id,
      employeeId: state.employeeId,
      firstName: state.firstName,
      middleName: state.middleName,
      lastName: state.lastName,
      dateOfJoining: state.dateOfJoining,
      contractType: state.contractType,
      lineManager: state.lineManager,
      email: state.email,
      department: state.department,
      costCenter: state.costCenter,
      gender: state.gender,
      status: state.status,
      country: state.country,
      roleNames:
        state.roleNames.length > 1
          ? state.roleNames.join(",")
          : state.roleNames[0],
      designation: state.designation,
      lastWorkingDate: state.lastWorkingDate,
      resignationDate: state.resignationDate
    };
    console.log("RESIGNATION UPDATE", updateEmployeeItem)
    let url = "api/HrEmployeeDetail/UpdateEmployeeDetails";
    jwtInterceptor.post(url, updateEmployeeItem).then((response) => {
      showMessage(response.data, "success");
      GetEmployeeListData();
    });
    setOpen((pre) => !pre);

  };

  const deleteEmployee = async () => {
    let url =
      "api/HrEmployeeDetail/DeleteEmployeeDetails?EmployeeDetailId=" + id;
    jwtInterceptor.delete(url).then((response) => {
      showMessage(response.data, "success");
      GetEmployeeListData();
    });
  };

  const onSubmit = () => {

    // Exclude middleName from the rest object for general validation
    const { middleName, ...restForGeneralValidation } = state;
    // For resignation validation, include resignationDate and lastWorkingDate
    const { resignationDate, lastWorkingDate } = state;
    const restForResignationValidation = { ...restForGeneralValidation, resignationDate, lastWorkingDate };

    console.log(" 606", { middleName, resignationDate, lastWorkingDate, ...restForGeneralValidation });

    if (btnType === "Edit") {
      if (activeTab === 0) {
        const foundErrors = validateEmployeeInfo(restForGeneralValidation);
        console.log(" TEST ERRORS", foundErrors);

        if (foundErrors.length > 0) {
          dispatch({ type: "errors", value: foundErrors });
          return;
        }
        updatEmployee();
      } else {
        const foundErrors = validateEmployeeResignationInfo(restForResignationValidation);
        console.log(" TEST ERRORS", foundErrors);
        if (foundErrors.length > 0) {
          dispatch({ type: "errors", value: foundErrors });
          return;
        }
        updatEmployeeResignation();
      };
    } else {
      const foundErrors = validateEmployeeInfo(restForGeneralValidation);
      console.log(" TEST ERRORS", foundErrors);

      if (foundErrors.length > 0) {
        dispatch({ type: "errors", value: foundErrors });
        return;
      }
      createNewemployee();
    }
    dispatch({ type: "reset" });
  };
  // const onSubmit = () => {
  //   // Exclude middleName, resignationDate, and lastWorkingDate from the rest object
  //   const { middleName, resignationDate, lastWorkingDate, ...rest } = state;
  //   // Now, rest does not include middleName, resignationDate, and lastWorkingDate
  //   console.log(" 606",{ middleName, resignationDate, lastWorkingDate, ...rest })


  //   if (btnType !== "Edit") {
  //     const foundErrors = validateEmployeeInfo(rest);
  //     console.log(" TEST ERRORS", foundErrors);

  //     if (foundErrors.length > 0) {
  //       dispatch({ type: "errors", value: foundErrors });
  //       return;
  //     }
  //     createNewemployee()
  //   }
  //   else if (value === 0) {
  //     const foundErrors = validateEmployeeInfo(rest);
  //     console.log(" TEST ERRORS", foundErrors);

  //     if (foundErrors.length > 0) {
  //       dispatch({ type: "errors", value: foundErrors });
  //       return;
  //     }
  //     updatEmployee()
  //   }
  //   else {
  //     const foundErrors = validateEmployeeResignationInfo(rest);
  //     console.log(" TEST ERRORS", foundErrors);

  //     if (foundErrors.length > 0) {
  //       dispatch({ type: "errors", value: foundErrors });
  //       return;
  //     }

  //     updatEmployeeResignation()
  //   };

  //   dispatch({ type: "reset" });
  // };
  const uploadDocumentsData = async () => {
    let url =
      "api/HrEmployeeDocument/UploadEmployeeDocument?EmployeeDetailId=" +
      selectedId;

    jwtInterceptor.post(url, formData).then((response) => {
      showMessage(response.data, "success");
    });
  };
  function openModel() {
    setOpen((pre) => !pre);
    setmodelTypeState("Add New Employee Information");
    setIsAdd(true)

  }

  function onEdit(from, item) {
    setOpen(true);
    setbtnTypeState("Edit");
    console.log(" FORM HERE", from)
    console.log(" TEST EDIT", item);
    const lastWorkingDate = dayjs(state.lastWorkingDate);
    console.log("--->", lastWorkingDate)
    setIdState(item.employeeDetailId);
    setActiveEmployeId(item.employeeId);

    dispatch({ type: "firstname", value: item.firstName });
    dispatch({ type: "middlename", value: item.middleName });
    dispatch({ type: "lastname", value: item.lastName });
    dispatch({ type: "joiningdate", value: item.dateOfJoining });
    dispatch({ type: "contracttype", value: item.contractType });

    dispatch({ type: "email", value: item.email });
    dispatch({ type: "gender", value: item.gender });

    dispatch({ type: "costcenter", value: item.costCenter });

    dispatch({ type: "status", value: item.status });

    dispatch({ type: "country", value: item.country });

    dispatch({ type: "lineManager", value: item.lineManager });
    dispatch({ type: "lastWorkingDate", value: item.lastWorkingDate });
    dispatch({ type: "resignationDate", value: item.resignationDate });

    dispatch({
      type: "roleType",
      value:
        typeof item.roleNames === "string"
          ? item.roleNames.split(",")
          : item.roleNames,
    });
    dispatch({ type: "department", value: item.department });

    dispatch({ type: "designation", value: item.designation });

    setmodelTypeState("Update Employee Information");
  }

  function onDelete(eId) {
    setDeleteModal((pre) => !pre);
    setIdState(eId);
  }
  function onConfirmationDelete() {
    setDeleteModal((pre) => !pre);

    //setemployeeDataState(employeeData.find(x=>x.employeeDetailId != id));
    deleteEmployee();
  }

  function onDocumentUploadModel(eId) {
    setViewDocsModal((pre) => !pre);
    setselectedId(eId);
  }

  function onAssetAssignModel(eId) {
    setAssetsModal((pre) => !pre);
    setselectedId(eId);
    // GetAssetsListData();
  }

  function onDocumentUpload(eId) {
    if (formData) {
      setNewDocModal((pre) => !pre);
      uploadDocumentsData();
    }
  }

  function onAssetAssign(eId) {
    setAssetsModal((pre) => !pre);
  }
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    dispatch({
      type: "roleType",
      value: typeof value === "string" ? value.split(",") : value,
    });
  };
  const hasError = (field) => state.errors.includes(field);

  const handleSort = (property, order) => { };


  return (
    <>
      <EnhancedTable
        head={headCells}
        rows={employeeData}
        isAddable={true}
        onAddClick={() => {
          openModel();
          setbtnTypeState("Add")
          setActiveTab(0)
          setIsAdd(true)
        }}
        title="Employees information"
        loading={loading}
      />
      <BaseModal
        open={open}
        type={btnType}
        handleClose={() => {
          setOpen((pre) => !pre);
          dispatch({ type: "reset" });
        }}
        onSave={onSubmit}
        title={modelType}

      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: "flex", justifyContent: "center", }}>
            <Tabs value={activeTab} onChange={handleChange1} aria-label="basic tabs example" sx={{ borderBottom: 1, borderColor: 'divider', }}>
              <Tab label="Details" {...a11yProps(0)} sx={{ textTransform: "capitalize" }} />
              {btnType === "Edit" &&
                <Tab label="Resignation" {...a11yProps(1)} sx={{ textTransform: "capitalize" }} />
              }</Tabs>
          </Box>
          <CustomTabPanel value={activeTab} index={0}>
            <Grid
              container
              spacing={2}
              sx={{
                overflowX: "hidden",
              }}
            >
              {btnType === "Edit" &&
                <Grid item xs={12} sm={6}>
                  <Typography variant="SmallBody">{t("Employee ID")} </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={t("EMP - 0026")}
                    value={activeEmployeId}
                    onChange={(e) =>
                      dispatch({ type: "firstname", value: e.target.value })
                    }
                    disabled
                    error={hasError("firstName")}
                    helperText={
                      hasError("firstName") ? t("Please enter first name") : null
                    }

                  />
                </Grid>
              }
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Cost centre")}</Typography>
                <TextField
                  variant="outlined"
                  placeholder={t("Enter cost centre")}
                  value={state.costCenter}
                  // disabled="true"
                  onChange={(e) =>
                    dispatch({ type: "costcenter", value: e.target.value })
                  }
                  error={hasError("costCenter")}
                  helperText={
                    hasError("costCenter") ? t("Please enter cost center") : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("First Name")} </Typography>
                <TextField
                  variant="outlined"
                  placeholder={t("Enter First Name")}
                  value={state.firstName}
                  onChange={(e) =>
                    dispatch({ type: "firstname", value: e.target.value })
                  }
                  error={hasError("firstName")}
                  helperText={
                    hasError("firstName") ? t("Please enter first name") : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Last Name")}</Typography>
                <TextField
                  variant="outlined"
                  placeholder={t("Enter Last Name")}
                  value={state.lastName}
                  onChange={(e) =>
                    dispatch({ type: "lastname", value: e.target.value })
                  }
                  error={hasError("lastName")}
                  helperText={
                    hasError("lastName") ? t("Please enter last name") : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Middle Name")}</Typography>
                <TextField
                  variant="outlined"
                  placeholder={t("Enter Middle Name")}
                  value={state.middleName}
                  onChange={(e) =>
                    dispatch({ type: "middlename", value: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Joining Date")}</Typography>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                >
                  <DatePicker
                    name="dateOfBirth"
                    defaultValue={dayjs(state.dateOfJoining)}
                    format="DD/MM/YYYY"
                    onChange={(e) =>
                      dispatch({ type: "joiningdate", value: e.$d.toISOString() })
                    }
                  />
                </LocalizationProvider>
                {errorHelperText(
                  hasError("dateOfJoining")
                    ? t("Please Select a joining date")
                    : null
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Gender")}</Typography>
                <Select
                  variant="outlined"
                  value={state.gender}
                  onChange={(e) =>
                    dispatch({ type: "gender", value: e.target.value })
                  }
                  error={hasError("gender")}
                  placeholder={t("Select Gender")}
                >
                  <MenuItem disabled value="">
                    <em>{t("Gender")}</em>
                  </MenuItem>
                  {gendersListData.map((item, i) => {
                    return (
                      <MenuItem value={item.gender} key={i}>
                        {t(item.gender)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errorHelperText(
                  hasError("gender") ? t("Please select a gender") : null
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Country")}</Typography>
              
                <CountrySelect
                  dispatch={dispatch}
                  value={state.country}
                  helperText={hasError("country") && t("Country is required")}
                  error={hasError("country")}
                  customFun={(e) => {
                    dispatch({ type: "country", value: e });
                  }}
                />
                  {errorHelperText(
                  hasError("country") ? t("Please select a country") : null
                )}
              </Grid>

             

              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Contract type")}</Typography>

                <Select
                  variant="outlined"
                  value={state.contractType}
                  onChange={(e) =>
                    dispatch({ type: "contracttype", value: e.target.value })
                  }
                  error={hasError("contractType")}
                  placeholder={t("Select Contract Type")}
                >
                  <MenuItem disabled value="">
                    <em>{t("Contract Type")}</em>
                  </MenuItem>
                  {contractsListData.map((item, i) => {
                    return (
                      <MenuItem value={item.contractType} key={i}>
                        {t(item.contractType)}
                      </MenuItem>
                    );
                  })}
                </Select>

                {errorHelperText(
                  hasError("contractType") ? t("Please select contract type") : null
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Line Manager")}</Typography>
                <Select
                  variant="outlined"
                  placeholder={t("Select manager")}
                  value={state.lineManager}
                  onChange={(e) =>
                    dispatch({ type: "lineManager", value: e.target.value })
                  }
                  error={hasError("lineManager")}
                >
                  <MenuItem disabled value="">
                    <em>{t("Line Manager")}</em>
                  </MenuItem>
                  {managersListData.map((item, i) => {
                    return (
                      <MenuItem value={item.managerId} key={i}>
                        {t(item.managerName)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errorHelperText(
                  hasError("lineManager") ? t("Please select a manager") : null
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Email")}</Typography>
                <TextField
                  variant="outlined"
                  placeholder={t("Enter email address")}
                  value={state.email}
                  onChange={(e) =>
                    dispatch({ type: "email", value: e.target.value })
                  }
                  error={hasError("email")}
                  helperText={
                    hasError("email") ? t("Please enter valid email") : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Department")}</Typography>

                <Select
                  variant="outlined"
                  placeholder={t("Select Department")}
                  value={state.department}
                  onChange={(e) =>
                    dispatch({ type: "department", value: e.target.value })
                  }
                  error={hasError("department")}
                >
                  <MenuItem disabled value="">
                    <em>{t("Department")}</em>
                  </MenuItem>
                  {departmentsListData.map((item, i) => {
                    return (
                      <MenuItem value={item.department} key={i}>
                        {t(item.department)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errorHelperText(
                  hasError("department") ? t("Please select a department") : null
                )}
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Role")}</Typography>
                <Select
                  multiple
                  displayEmpty
                  variant="outlined"
                  placeholder={t("Select role")}
                  value={state.roleNames}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>{t("Roles")}</em>;
                    }

                    return selected.join(", ");
                  }}
                  onChange={handleChange}
                  error={hasError("roleNames")}
                >
                  <MenuItem disabled value="">
                    <em>{t("Roles")}</em>
                  </MenuItem>
                  {rolesListData.map((item, i) => (
                    <MenuItem value={item.roleType} key={i}>
                      {t(item.roleType)}
                    </MenuItem>
                  ))}
                </Select>
                {errorHelperText(
                  hasError("roleNames") ? t("Please select a permission") : null
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Designation")}</Typography>
                <TextField
                  variant="outlined"
                  placeholder={t("Enter Designation")}
                  value={state.designation}
                  onChange={(e) =>
                    dispatch({ type: "designation", value: e.target.value })
                  }
                  error={hasError("designation")}
                  helperText={
                    hasError("designation") ? t("Please enter designation") : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="SmallBody">{t("Status")}</Typography>
  
                <Select
                  variant="outlined"
                  value={state.status}
                  onChange={(e) =>
                    dispatch({ type: "status", value: e.target.value })
                  }
                  error={hasError("status")}
                  placeholder={t("Select Status")}
                >
                  <MenuItem disabled value="">
                    <em>{t("Status")}</em>
                  </MenuItem>
                  <MenuItem value="Active">{t("Active")}</MenuItem>
                  <MenuItem value="Deactive">{t("Deactive")}</MenuItem>
                </Select>
                {errorHelperText(
                  hasError("status") ? t("Please select a status") : null
                )}
              </Grid>
            </Grid>
          </CustomTabPanel>

          {btnType === "Edit" &&
            <CustomTabPanel value={activeTab} index={1}>
              <Grid
                container
                spacing={2}
                sx={{
                  overflowX: "hidden",
                }}
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="SmallBody">{t("Employee ID")} </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={t("EMP - 0026")}
                    value={id}
                    onChange={(e) =>
                      dispatch({ type: "firstname", value: e.target.value })
                    }
                    disabled
                    error={hasError("firstName")}
                    helperText={
                      hasError("firstName") ? t("Please enter first name") : null
                    }

                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="SmallBody">{t("Joining Date")}</Typography>

                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      name="dateOfBirth"
                      defaultValue={dayjs(state.dateOfJoining)}
                      disabled

                      format="DD/MM/YYYY"
                      onChange={(e) =>
                        dispatch({ type: "joiningdate", value: e.$d.toISOString() })
                      }
                    />
                  </LocalizationProvider>
                  {errorHelperText(
                    hasError("dateOfJoining")
                      ? t("Please Select a joining date")
                      : null
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="SmallBody">{t("First Name")} </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={t("Enter First Name")}
                    value={state.firstName}
                    onChange={(e) =>
                      dispatch({ type: "firstname", value: e.target.value })
                    }
                    disabled

                    error={hasError("firstName")}
                    helperText={
                      hasError("firstName") ? t("Please enter first name") : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="SmallBody">{t("Last Name")}</Typography>
                  <TextField
                    variant="outlined"
                    placeholder={t("Enter Last Name")}
                    value={state.lastName}
                    onChange={(e) =>
                      dispatch({ type: "lastname", value: e.target.value })
                    }
                    disabled

                    error={hasError("lastName")}
                    helperText={
                      hasError("lastName") ? t("Please enter last name") : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="SmallBody">{t("Resignation Date ")}</Typography>

                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      defaultValue={state.resignationDate ? dayjs(state.resignationDate) : undefined}
                      onChange={(e) => {
                        dispatch({ type: "resignationDate", value: e.$d.toISOString() })
                      }}
                      maxDate={dayjs()}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {errorHelperText(
                    hasError("resignationDate")
                      ? t("Please Select a resignation date")
                      : null
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="SmallBody">{t("Last Working Date")}</Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                  >
                    <DatePicker
                      defaultValue={state.lastWorkingDate ? dayjs(state.lastWorkingDate) : undefined}
                      onChange={(e) => {
                        dispatch({ type: "lastWorkingDate", value: e.$d.toISOString() })
                      }}
                      minDate={dayjs(state.resignationDate)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {errorHelperText(
                    hasError("lastWorkingDate")
                      ? t("Please Select a last working date")
                      : null
                  )}
                </Grid>
              </Grid>
            </CustomTabPanel>
          }

        </Box>
      </BaseModal>
      <DeleteModal
        open={deleteModal}
        onCancel={() => setDeleteModal((pre) => !pre)}
        title="Delete Employee"
        description="Are you sure you want to delete this employee?"
        onConfirm={() => onConfirmationDelete()}
      />
      <NewAssetModal
        open={AssetsModal}
        handleClose={() => setAssetsModal(false)}
        onSave={() => {
          onAssetAssign();
        }}
        selectedId={selectedId}
        title="Add new asset"
      />
      <ViewDocModal
        open={viewDocsModal}
        handleClose={() => setViewDocsModal(false)}
        title="View document"
        selectedId={selectedId}
      />
    </>
  );
}

export default EmployeeSkillsTable;