import React, { useReducer, useState, useEffect, useRef } from "react";
import EnhancedTable from "../Global/Table";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BaseModal from "../Global/Modal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RoundedChip } from "../Global/Chips";
import DeleteModal from "../Global/DeleteModal";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { hasError, validateCalculate } from "../../utils/validation";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../Global/WithSnackbar";
import jwtInterceptor, { jwtLeave } from "../../services/interceptors";

import { Pending } from "@mui/icons-material";
import Select from "../Global/Select";

const headCells = [
  {
    id: "fullName",
    label: "Full Name",
  },
  {
    id: "leave",
    label: "Leave",
  },
  {
    id: "startingDate",
    label: "Starting Date",
  },
  {
    id: "endDate",
    label: "End Date",
  },
  {
    id: "noOfDays",
    label: "No. of days",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "Action",
    label: "Action",
  },
];

function createData(
  fullName,
  leave,
  startDate,
  endDate,
  noOfDays,
  status,
  action
) {
  return { fullName, leave, startDate, endDate, noOfDays, status, action };
}

const pending = <RoundedChip status="Pending" color="#E2B93B" />;
const approved = <RoundedChip status="Approved" color="#27AE60" />;
const rejected = <RoundedChip status="Rejected" color="#EB5757" />;

function CellAction({ onDelete }) {
  return (
    <Box className="action-icon-rounded">
      {/*<Button
        sx={{
          backgroundColor: alpha("#27AE60", 0.1),

          svg: {
            fill: "#27AE60",
          },
        }}
      >
        <BorderColorIcon />
      </Button>*/}
      <Button
        sx={{
          backgroundColor: alpha("#DF6F79", 0.1),

          svg: {
            fill: "#DF6F79",
          },
        }}
        onClick={onDelete}
      >
        <DeleteIcon />
      </Button>
      <Button>
        <MoreVertIcon />
      </Button>
    </Box>
  );
}

function EmployeeSkillsTable() {
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "fullName":
          return { ...prevState, employeeDetailId: action.value };
        case "leaveType":
          return { ...prevState, leaveTypeId: action.value };
        case "startDate":
          let sDate =
            action.value.$y +
            "-" +
            ("0" + (action.value.$M + 1)).slice(-2) +
            "-" +
            ("0" + action.value.$D).slice(-2);
          return { ...prevState, startDate: sDate };
        case "endDate":
          let eDate =
            action.value.$y +
            "-" +
            ("0" + (action.value.$M + 1)).slice(-2) +
            "-" +
            ("0" + action.value.$D).slice(-2);
          return { ...prevState, endDate: eDate };
        case "halfDay":
          return {
            ...prevState,
            isHalfDay: action.value,
          };
        case "weekends":
          return {
            ...prevState,
            isIncludingWeekand: action.value,
          };
        case "totalDays":
          return {
            ...prevState,
            totalDays: action.value,
          };
        case "comments":
          return {
            ...prevState,
            employeeComments: action.value,
          };
        default:
          throw new Error();
      }
    },
    {
      employeeDetailId: 0,
      leaveTypeId: 0,
      totalDays: 0,
      startDate: "",
      endDate: "",
      isHalfDay: false,
      isIncludingWeekand: false,
      employeeComments: "",
    }
  );

  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const tblRows = [];
  const LeaveTypes = [];
  const initialized = useRef(false);
  const [leavesData, setleavesDataState] = useState([]);
  const [leavesListData, setleavesListDataState] = useState([]);
  const [employeeListData, setemployeeListDataState] = useState([]);
  const [errors, setErrors] = useState([]);
  const [dateError, setDateError] = useState("");
  const [save, setSave] = useState(false);

  const [loading, setLoading] = useState(false);
  const [leaveId, setIdState] = useState(null);
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const bearerToken = sessionStorage.getItem("token_key");

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      GetLeavesListData();
      GetEmployeeListData();
      GetLeavesConfigurationData();
    }
  }, []);

  function onDelete(eId) {
    setDeleteOpen((pre) => !pre);
    setIdState(eId);
  }
  function onConfirmationDelete() {
    setDeleteOpen((pre) => !pre);
    deleteEmployeeLeave();
  }

  const deleteEmployeeLeave = async () => {
    let url = "api/HrLeave/DeleteEmployeeLeaveDetail?leaveDetailId=" + leaveId;
    jwtLeave
      .delete(url)
      .then((response) => {
        showMessage(response, "success");
        GetLeavesListData();
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const GetLeavesConfigurationData = async () => {
    let url = "api/LeaveConfiguration/GetAllLeaveConfiguration";
    jwtLeave
      .get(url)
      .then((response) => {
        for (var x of response.data) {
          let item = {
            leaveTypeId: x.leaveTypeId,
            leaveType: x.leaveType,
          };
          LeaveTypes.push(item);
        }
        setleavesListDataState(LeaveTypes);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });
  };
  const GetEmployeeListData = async () => {
    setLoading(true);

    jwtInterceptor
      .get("api/HrEmployeeDetail/GetEmployeeDetailsList")
      .then((response) => {
        if (response.status !== 200) {
          showMessage(response.statusText, "error");
          navigate("/login", { replace: true });
        }
        let rows = [];
        for (var x of response.data) {
          let item = {
            employeeDetailId: x.employeeDetailId,
            fullName: x.fullName,
          };
          rows.push(item);
        }

        setemployeeListDataState(rows);
      })
      .finally(() => setLoading(false));
  };

  const GetLeavesListData = async () => {
    setLoading(true);
    let url = "api/HrLeave/GetAllEmployeeLeaveList";
    jwtLeave
      .get(url)
      .then((response) => {
        for (var x of response.data) {
          let eId = x.leaveDetailId;
          let leaveStatus = Pending; // (x.leaveStatus == "Pending"?Pending:(x.leaveStatus=="Approved"?approved:rejected));
          switch (x.leaveStatus) {
            case "Pending":
              leaveStatus = pending;
              break;
            case "Approved":
              leaveStatus = approved;
              break;
            case "Rejected":
              leaveStatus = rejected;
              break;
          }
          tblRows.push(
            createData(
              x.fullName,
              x.leaveType,
              new Date(x.startDate).toLocaleDateString("en-GB"),
              new Date(x.endDate).toLocaleDateString("en-GB"),
              x.totalDays,
              leaveStatus,
              <CellAction onDelete={() => onDelete(x.leaveDetailId)} />
            )
          );
        }
        setleavesDataState(tblRows);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
  };
  const checkIsEndDateIsAfterStartDate = () => {
    const { startDate, endDate } = state;
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      setDateError("End date should be after start date or same as start date");
      return false;
    }
    setDateError("");
    return true;
  };

  const GetCaluclateLeavesData = async () => {
    const { leaveTypeId, startDate, endDate } = state;

    const errors = validateCalculate({ leaveTypeId, startDate, endDate });

    if (errors.length > 0 || !checkIsEndDateIsAfterStartDate()) {
      setErrors(errors);
    } else {
      setErrors([]);
      let calculateLeaves = {
        employeeDetailId: state.employeeDetailId,
        leaveTypeId: state.leaveTypeId,
        startDate: state.startDate,
        endDate: state.endDate,
        isHalfDay: state.isHalfDay,
        isIncludingWeekand: state.isIncludingWeekand,
      };

      let url = "api/EmployeeLeave/GetCalculateDays";

      jwtLeave
        .post(url, calculateLeaves)
        .then((response) => {
          if (response && response.data.statusCode === 200) {
            if (response.data.data.leaveCount <= 0)
              showMessage(response.data.message, "error");
            else {
              dispatch({
                type: "totalDays",
                value: response.data.data.leaveCount
                  ? response.data.data.leaveCount
                  : 0,
              });
              setSave(true);
            }
          } else {
            // showMessage(response.message, "success");
            showMessage(response.data.message, "error");
          }
        })
        .catch((err) => {
          showMessage(err.message, "error");
        })
        .finally(() => setLoading(false));
    }
  };
  const onSubmit = () => {
    createNewLeave();
  };
  const createNewLeave = async () => {
    let url = "api/HrLeave/CreateEmployeeLeave";

    jwtLeave
      .post(url, state)
      .then((response) => {
        setOpen(false);
        GetLeavesListData();
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <EnhancedTable
        head={headCells}
        rows={leavesData}
        isAddable={true}
        onAddClick={() => setOpen((pre) => !pre)}
        title="Employees information"
      />
      <BaseModal
        open={open}
        handleClose={() => setOpen((pre) => !pre)}
        title="Leave Management - HR modification"
        onSave={onSubmit}
        showSaveButton={save}
      >
        <Grid
          container
          spacing={2}
          sx={{
            overflowX: "hidden",
          }}
        >
          {/*<Grid item xs={12} sm={6}>
            <Typography variant="SmallBody">{t("First Name")}</Typography>
            <TextField variant="outlined" placeholder="Employee Name" />
  </Grid>*/}
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody">{t("First Name")}</Typography>
            {/*<Select
            variant="outlined"
            placeholder={t("Select First Name")}
            value={state.fullName}
            onChange={(e) =>
              dispatch({ type: "fullName", value: e.target.value })
            }
            error={hasError("employeeDetailId", errors)}
          >
            {employeeListData.map((item, i) => (
              <MenuItem value={item.employeeDetailId} key={i}>
                {t(item.fullName)}
              </MenuItem>
            ))}
          </Select>  */}
            <Select
              variant="outlined"
              placeholder={t("Select leave type")}
              value={state.fullName}
              onChange={(e) =>
                dispatch({ type: "fullName", value: e.target.value })
              }
              error={hasError("leaveTypeId", errors)}
              MenuProps={{
                sx: {
                  maxHeight: "400px",
                },
              }}
            >
              {employeeListData.map((item, i) => (
                <MenuItem value={item.employeeDetailId} key={i}>
                  {t(item.fullName)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {/*<Grid item xs={12} sm={6}>
            <Typography variant="SmallBody">{t("Last Name")}</Typography>
            <TextField variant="outlined" placeholder="Doe" />
          </Grid>*/}
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody">{t("Leave Type")}</Typography>
            <Select
              variant="outlined"
              placeholder={t("Select leave type")}
              value={state.leaveType}
              onChange={(e) =>
                dispatch({ type: "leaveType", value: e.target.value })
              }
              error={hasError("leaveTypeId", errors)}
            >
              {leavesListData.map((item, i) => (
                <MenuItem value={item.leaveTypeId} key={i}>
                  {t(item.leaveType)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {/*<Grid item xs={12} sm={3} mt={1}>
            <Typography variant="SmallBody">{t("Leave Status")}</Typography>
            <Stack direction="row" gap={2} alignItems="center">
              <Checkbox />
              <Typography variant="Small">{t("Approve")}</Typography>
            </Stack>
            </Grid>*/}
          <Grid item xs={12} sm={3} direction="column" mt={1}>
            <Typography variant="SmallBody">{t("No. of Days")}</Typography>
            <Stack alignItems="center" direction="row" mt={1}>
              <Typography variant="SmallBodyBold" color="warning">
                {state.totalDays}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" gap={1} alignItems="center">
                <Stack direction="row" gap={"5px"} alignItems="center">
                  <Checkbox
                    checked={state.halfDay}
                    onChange={(e) =>
                      dispatch({ type: "halfDay", value: e.target.checked })
                    }
                  />
                  <Typography variant="Small">{t("Half day")}</Typography>
                </Stack>
                <Stack direction="row" gap={"5px"} alignItems="center">
                  <Checkbox
                    checked={state.fullDay}
                    onChange={(e) =>
                      dispatch({ type: "weekends", value: e.target.checked })
                    }
                  />
                  <Typography variant="Small">
                    {t("Including weekends")}
                  </Typography>
                </Stack>
              </Stack>
              <Button
                variant="outlined"
                onClick={GetCaluclateLeavesData}
                sx={{ padding: "2px 20px", width: "fit-content" }}
              >
                {t("calculate")}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody">{t("Start Date")}</Typography>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={i18n.language}
            >
              <DatePicker
                onChange={(e) => dispatch({ type: "startDate", value: e })}
                format="DD/MM/YYYY"
                value={dayjs(state.startDate)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody">{t("End Date")}</Typography>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={i18n.language}
            >
              <DatePicker
                onChange={(e) => dispatch({ type: "endDate", value: e })}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Grid>
          {/* FUll width */}
          <Grid item xs={12}>
            <Typography variant="SmallBody">{t("Comments")}</Typography>
            <TextField
              variant="outlined"
              placeholder={t("Enter comments")}
              multiline
              rows={3}
              onChange={(e) =>
                dispatch({ type: "comments", value: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </BaseModal>
      <DeleteModal
        open={deleteOpen}
        onCancel={() => setDeleteOpen((pre) => !pre)}
        //onDelete={() => setDeleteOpen((pre) => !pre)}
        onConfirm={() => onConfirmationDelete()}
        title="Delete Leave"
        message="Are you sure you want to delete this leave?"
      />
    </>
  );
}

export default EmployeeSkillsTable;
