import React, { useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

const Dropdown = ({ setIsMonthlyLeaves }) => {
  const [value, setValue] = useState('Monthly');

  const handleChange = (event) => {
    setValue(event.target.value);

    if (value === 'Monthly') {
      setIsMonthlyLeaves(false);
    } else {
      setIsMonthlyLeaves(true);
    }
  };

  return (
    <>
      <FormControl>
        <Select
          labelId='demo-simple-select-autowidth-label'
          id='demo-simple-select-autowidth'
          value={value}
          onChange={handleChange}
          autoWidth
        >
          <MenuItem value={'Monthly'}>Monthly</MenuItem>
          <MenuItem value={'Annually'}>Annually</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default Dropdown;
