import React, { useContext, useState } from "react";
import { TopbarRoot } from "./Topbar.styles";
import { Badge, Box, Drawer, Stack, useMediaQuery } from "@mui/material";
import LanguageSelector from "./LanguageSelector/LanguageSelector";
import Favorites from "./Favorites/Favorites";
import ModeSwitch from "./ModeSwitch";
import UserInfo from "./UserInfo/UserInfo";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation } from "react-router-dom";
import { themeContext } from "../../../theme";
import NotificationsIcon from '@mui/icons-material/Notifications';
const RenderMenu = ({ isMobile, children }) => {
  const [open, setOpen] = useState(false);
  return isMobile ? (
    <>
      <MenuIcon onClick={() => setOpen(true)} />
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Stack direction={"column"} sx={{ height: "100%", p: 2 }}>
          {children}
        </Stack>
      </Drawer>
    </>
  ) : (
    <>{children}</>
  );
};

function Topbar({ favs, refetchFavs }) {
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const { pathname } = useLocation();
  const { myTheme } = useContext(themeContext);

  return (
    <TopbarRoot
      sx={{
        justifyContent: pathname === "/" ? "space-between" : "flex-end",
        ...(isTablet && {
          p: "25px",
        }),
        width: "100%"
      }}
    >
      {pathname === "/" && (
        <img
          src={myTheme.logo}
          alt="logo"
          style={{
            width: "150px",
            height: "auto",
            mr: "auto",
          }}
        />
      )}

      <RenderMenu isMobile={isTablet} >
        {/* <SearchBar
          variant="outlined"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            ml: isTablet ? 0 : "166px",
          }}
        /> */}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: isTablet ? "column" : "row",
            alignItems: "center",
          }}
        >
          <LanguageSelector isTablet={isTablet} />
          <Favorites
            isTablet={isTablet}
            favs={favs}
            getFavsData={refetchFavs}
          />
          {/* <Notifications isTablet={isTablet} /> */}
          {/* <Badge color="error" overlap="circular" badgeContent="" variant="dot">
            <NotificationsIcon isTablet={isTablet} />
          </Badge> */}
          <ModeSwitch isTablet={isTablet} />
          <UserInfo />
        </Box>
      </RenderMenu>
    </TopbarRoot>
  );
}

export default Topbar;
