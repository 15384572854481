import { Button, LinearProgress, Stack, Typography, Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { DashboardImg } from "../../assets/images";
import { FaArrowRight } from "react-icons/fa6";
import { customColor } from "../../theme/customColor";
import TextIcon from "../../components/Icon/TextIcon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import profileService from "../../services/myprofile";
import useStyles from "./IconStyle";
const counts = {
  totalSickLeaves: 0,
  totalAnnualLeaves: 0,
};
function DashboardHeader() {
  const classes = useStyles();
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [topCounts, settopCountsState] = useState(counts);

  const getLeaveDetails = async () => {
    let url = "api/EmployeeLeave/GetLeaveDashboardYTD?EmployeeDetailId=" + empId + "&Year=" + currentYear;
    let response = await profileService.GetLeavesListDataRequest(
      url,
      bearerToken
    );
    settopCountsState({
      totalSickLeaves: response.totalSickLeaves,
      totalAnnualLeaves: response.totalAnnualLeaves
    });
  };
  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        getLeaveDetails();
      } else {
        window.location.href = "/login";
      }
    }
  }, []);
  return (
    <>
      <Box flex={1} >
        <Typography fontWeight={500} fontSize={15}>{t("My Leave Management")}</Typography>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 4,
          }}
        >
          <Box >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: 1,
                alignItems: "center",
                pb: 1
              }}
            >
              <TextIcon
                color="#19C03E"
                fontSize={16}
                icon="ion:exit"
                size={25}
              />
              <Typography color={(theme) => theme.palette.action.disabled} fontWeight={700} sx={{ fontSize: 12 }}  >
                {t("Annual Leave")}
              </Typography>
            </Box>
            <Typography variant="h8" fontWeight={600} mt={1} mb={1}>
              {topCounts.totalAnnualLeaves} days
            </Typography>
            <LinearProgress
              variant="determinate"
              value={topCounts.totalAnnualLeaves}
              sx={{ borderRadius: 999 }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: 1,
                alignItems: "center",
                pb: 1

              }}
            >
              <TextIcon
                color="#964CF5"
                fontSize={16}
                icon="mdi:fever"
                size={25}
              />
              <Typography color={(theme) => theme.palette.action.disabled} fontWeight={700} sx={{ fontSize: 12 }}  >
                {t("Sick Leave")}
              </Typography>
            </Box>
            <Typography variant="h7" fontWeight={600} mt={1} mb={1}>
              {topCounts.totalSickLeaves} days
            </Typography>
            <LinearProgress
              variant="determinate"
              value={topCounts.totalSickLeaves}
              color="error"
              sx={{ borderRadius: 999 }}
            />
          </Box>
        </Box>
        <Stack sx={{
          background: `url(${DashboardImg}) no-repeat`,
          backgroundSize: "cover",
          p: 2,
          borderRadius: 3,
          ".MuiTypography-root": { color: "#fff" },
          alignItems: "flex-start",
          minHeight: 220,
          mt: 3,
        }}>
          <Typography variant="h7" fontWeight={600}>
            {t("Corporate documents")}
          </Typography>
          <Typography style={{ fontSize: '0.7rem' }} sx={{ mt: 1 }} >
            {t("Here is the repository for corporate documents.")}
          </Typography>
          <Button
            endIcon={<FaArrowRight />}
            sx={{
              svg: { fontSize: "14px !important" },
              mt: "auto",
              color: "#fff !important"
            }}
            style={{ fontSize: '0.7rem' }}
            onClick={() => navigate("/presentations")}
          >
            Read More
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default DashboardHeader;
