import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import EmployeeSkillsTable from "./EmployeeSkillsTable";
import ManagerSkillsTable from "./ManagerSkillsTable";
import { useTranslation } from "react-i18next";
import { DistributionColor } from "../../../data";

const styles = {
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 850px)": {
      flexDirection: "column",
      gap: "15px",
    },
  },
  optionsContainer: {
    display: "flex",
    gap: "15px",
  },
};
export const SelectCatStyle = (theme) => ({
  padding: "8px 10px",
  background: theme.palette.background.lightBack,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "40px",
  color: theme.palette.primary.main,
});

const RenderTable = ({ subMenu }) => {
  switch (subMenu) {
    case "Employee":
      return <EmployeeSkillsTable />;
    case "Manager":
      return <ManagerSkillsTable />;
    default:
      return <EmployeeSkillsTable />;
  }
};

function SkillsTable() {
  const [subMenu, setSubMenu] = useState("Employee");
  const roleskey = sessionStorage.getItem("roles");
  let isManager = roleskey.includes("Manager") ? "flex" : "none";
  const { t } = useTranslation();
  return (
    <Box className="primary-border">
      <Box sx={styles.topContainer}>
        <Box sx={styles.optionsContainer}>
          <Box
            className="c-l"
            sx={(theme) => ({
              cursor: "pointer",

              ...(subMenu === "Employee" && SelectCatStyle(theme)),
            })}
            onClick={() => setSubMenu("Employee")}
          >
            <PersonIcon />
            <Typography variant="smallBody">{t("Employee")}</Typography>
          </Box>
          <Box
            className="c-l"
            sx={(theme) => ({
              cursor: "pointer",
              display: isManager,

              ...(subMenu === "Manager" && SelectCatStyle(theme)),
            })}
            onClick={() => setSubMenu("Manager")}
          >
            <PeopleIcon />
            <Typography variant="smallBody">{t("Manager")}</Typography>
          </Box>
        </Box>

      </Box>
      <Box>
        <RenderTable subMenu={subMenu} />

        <Box sx={{display:'flex', gap: 4}}>
          {Object.entries(DistributionColor).map(([key, color]) => (
            <Box key={key} sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Box sx={{ width: '10px', height: '10px', backgroundColor: color, borderRadius: '100px', fontWeight: '500' }} />
              <Typography sx={{fontSize: 10}}>{key}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default SkillsTable;
