import React, { useState } from "react";
import EnhancedTable from "../Global/Table";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BaseModal from "../Global/Modal";
import DragAndDrop from "../Global/DragAndDrop";
import DeleteModal from "../Global/DeleteModal";
import { useTranslation } from "react-i18next";
import Select from "../Global/Select";

const headCells = [
  {
    id: "employeeId",
    label: "Employee ID",
  },
  {
    id: "fullName",
    label: "Full Name",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "modified",
    label: "Modified",
  },
  {
    id: "modifiedBy",
    label: "Modified By",
  },
  {
    id: "Action",
    label: "Action",
  },
];

function createData(
  employeeId,
  fullName,
  title,
  type,
  modified,
  modifiedBy,
  action
) {
  return {
    employeeId,
    fullName,
    title,
    type,
    modified,
    modifiedBy,
    action,
  };
}

function CellAction({ onDelete }) {
  return (
    <Box className="action-icon-rounded">
      <Button
        sx={{
          backgroundColor: alpha("#27AE60", 0.1),

          svg: {
            fill: "#27AE60",
          },
        }}
      >
        <BorderColorIcon />
      </Button>
      <Button
        sx={{
          backgroundColor: alpha("#DF6F79", 0.1),

          svg: {
            fill: "#DF6F79",
          },
        }}
        onClick={onDelete}
      >
        <DeleteIcon />
      </Button>
      <Button>
        <MoreVertIcon />
      </Button>
    </Box>
  );
}

function EmployeeSkillsTable() {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const rows = [
    createData(
      "EMP - 0025",
      "Steeve Farnandas",
      "Finance Exc.",
      "PDF",
      "09 Sep, 2021",
      "Shahab Bukhari",
      <CellAction onDelete={() => setDeleteOpen((pre) => !pre)} />
    ),
    createData(
      "EMP - 0025",
      "Steeve Farnandas",
      "Finance Exc.",
      "PDF",
      "09 Sep, 2021",
      "Shahab Bukhari",

      <CellAction onDelete={() => setDeleteOpen((pre) => !pre)} />
    ),
    createData(
      "EMP - 0025",
      "Steeve Farnandas",
      "Finance Exc.",
      "PDF",
      "09 Sep, 2021",
      "Shahab Bukhari",

      <CellAction onDelete={() => setDeleteOpen((pre) => !pre)} />
    ),
    createData(
      "EMP - 0025",
      "Steeve Farnandas",
      "Finance Exc.",
      "PDF",
      "09 Sep, 2021",
      "Shahab Bukhari",

      <CellAction onDelete={() => setDeleteOpen((pre) => !pre)} />
    ),
    createData(
      "EMP - 0025",
      "Steeve Farnandas",
      "Finance Exc.",
      "PDF",
      "09 Sep, 2021",
      "Shahab Bukhari",

      <CellAction onDelete={() => setDeleteOpen((pre) => !pre)} />
    ),
    createData(
      "EMP - 0025",
      "Steeve Farnandas",
      "Finance Exc.",
      "PDF",
      "09 Sep, 2021",
      "Shahab Bukhari",
      <CellAction onDelete={() => setDeleteOpen((pre) => !pre)} />
    ),
  ];

  const { t } = useTranslation();

  return (
    <>
      <EnhancedTable
        head={headCells}
        rows={rows}
        isAddable={true}
        onAddClick={() => setOpen((pre) => !pre)}
        title="Employees information"
      />
      <BaseModal
        open={open}
        handleClose={() => setOpen((pre) => !pre)}
        title="Upload new document"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody">{t("First Name")}</Typography>
            <TextField variant="outlined" placeholder={t("Enter First Name")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody">{t("Last Name")}</Typography>
            <TextField variant="outlined" placeholder={t("Enter Last Name")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody">{t("Employee ID")} </Typography>
            <TextField
              variant="outlined"
              placeholder={t("Enter Employee ID")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody">{t("Type")}</Typography>
            <Select
              variant="outlined"
              defaultValue="PDF"
              placeholder={t("Select Type")}
            >
              <MenuItem value="PDF">{t("PDF")}</MenuItem>
              <MenuItem value="DOC">{t("DOC")}</MenuItem>
              <MenuItem value="XLS">{t("XLS")}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="SmallBody">{t("Attachments")}</Typography>
            <DragAndDrop
              sx={{
                margin: "20px auto",
              }}
              onChangeFile={(fileObj) => console.log(fileObj)}
              allowMultiple={true}
            />
          </Grid>
        </Grid>
      </BaseModal>
      <DeleteModal
        open={deleteOpen}
        onCancel={() => setDeleteOpen((pre) => !pre)}
        onDelete={() => setDeleteOpen((pre) => !pre)}
        title={"Delete Employee Document"}
        message={"Are you sure you want to delete this document?"}
      />
    </>
  );
}

export default EmployeeSkillsTable;
