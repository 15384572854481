import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import profileService from "../../../services/myprofile";
import AddIcon from "@mui/icons-material/Add";
import CircularIcon from "../../Global/CircularIcon";
import { useSnackbar } from "../../Global/WithSnackbar";
import jwtInterceptor from "../../../services/interceptors";
import { useTranslation } from "react-i18next";
import { UserPlaceholder } from "../../../assets/images";

function ProfileInfo() {
  const [EMPLOYEE_INFO, setEmployeeInformation] = useState({});
  const profileInputRef = React.useRef(null);
  const [profilePic, setProfilePic] = useState(null);
  const initialized = useRef(false);
  const { showMessage } = useSnackbar();

  const { t } = useTranslation();

  const handleProfilePicUpload = () => {
    profileInputRef.current.click();
  };

  const handleProfilePicChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfilePic(URL.createObjectURL(event.target.files[0]));
      const formDataFiles = new FormData();
      formDataFiles.append("file", event.target.files[0]);

      uploadProfilePictureData(formDataFiles);
    }
  };

  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");
  const email = sessionStorage.getItem("email_key");

  const navigate = useNavigate();

  const getProfileData = async () => {
    jwtInterceptor
      .get("api/Employee/GetProfileTopSectionDetials?id=" + empId)
      .then((response) => {
        setEmployeeInformation((item) => ({
          ...EMPLOYEE_INFO,
          ...response.data,
        }));
      });
  };

  const getProfilePictureData = async () => {
    let pictureData = "";
    jwtInterceptor
      .get(
        "api/Employee/GetProfilePictureFileStream?EmployeeDetailId=" +
          empId +
          "&email=" +
          email
      )
      .then((response) => {
        pictureData = response;
      });
  };

  const uploadProfilePictureData = async (formDataFiles) => {
    let url = "api/Employee/UploadProfileImage?EmployeeDetailId=" + empId;
    /* let response = await profileService.uploadDocumentsDataRequest(
      url,
      formDataFiles,
      bearerToken
    );

    if (response) {
      showMessage(response, "success");
    }*/

    jwtInterceptor.post(url, formDataFiles).then((response) => {
      showMessage(response.data, "success");
    });
  };

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        getProfileData();
        // getProfilePictureData();
        setProfilePic(
          process.env.REACT_APP_API_PROFILE_SERVICE_URL +
            "/api/Employee/GetProfilePictureFileStream?EmployeeDetailId=" +
            empId
        );
      } else {
        window.location.href = "/login";
      }
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        padding: "30px",
        border: "1px solid rgba(9, 44, 76, 0.1)",
        borderRadius: "20px",

        "@media screen and (max-width: 600px)": {
          flexDirection: "column",
          gap: "10px",
          padding: "10px",
        },
      }}
    >
      <Box
        sx={{
          flex: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "10px",
          position: "relative",
          "& img": {
            border: "3px solid #FFFFFF",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: "50%",
            backgroundColor: "#dbbfec68",
            aspectRatio: "1/1",
            maxWidth: "180px",
            maxHeight: "180px",
            objectFit: "cover",

            width: "100%",
            height: "100%",
          },
        }}
      >
        <img
          src={profilePic}
          alt="profile"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = UserPlaceholder;
          }}
        />
        <Stack
          alignItems="center"
          justifyContent="center"
          position="relative"
          spacing={2}
        >
          <Box sx={{ position: "absolute", top: "-25px" }}>
            <Tooltip title={t("Upload Profile Picture")} placement="bottom">
              <CircularIcon
                icon={<AddIcon />}
                color="#092C4C"
                sx={{ width: "25px", height: "25px" }}
                opacity={0.2}
                onClick={handleProfilePicUpload}
              />
              <input
                type="file"
                style={{ display: "none" }}
                ref={profileInputRef}
                onChange={handleProfilePicChange}
                // only accept image files
                accept="image/*"
              />
            </Tooltip>
          </Box>
          <Typography variant="smallBody">{EMPLOYEE_INFO.fullName}</Typography>
          <Typography variant="extraSmallBody">
            {t(EMPLOYEE_INFO.designation)}
          </Typography>
        </Stack>
      </Box>
      <Grid
        container
        sx={{
          flex: 0.8,
        }}
        rowGap={2}
        columnGap={0.5}
        alignContent="flex-start"
      >
        {/* Employee Id */}
        <Grid
          key="1"
          sm={5.8}
          xs={11.9}
          md={3.95}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            textAlign: {
              xs: "center",
              sm: "left",
            },
          }}
        >
          <Typography variant="smallBody" className="text-ellipsis">
            {t("Employee Id")}
          </Typography>

          <Typography
            variant="smallBody"
            sx={{
              opacity: 0.7,
            }}
            fontWeight={400}
            className="text-ellipsis"
          >
            {EMPLOYEE_INFO.employeeId}
          </Typography>
        </Grid>
        {/* End */
        /* Line manager*/}
        <Grid
          key="1"
          sm={5.8}
          xs={11.9}
          md={3.95}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            textAlign: {
              xs: "center",
              sm: "left",
            },
          }}
        >
          <Typography variant="smallBody" className="text-ellipsis">
            {t("Line Manager")}
          </Typography>

          <Typography
            variant="smallBody"
            sx={{
              opacity: 0.7,
            }}
            fontWeight={400}
            className="text-ellipsis"
          >
            {EMPLOYEE_INFO.lineManagerFullName}
          </Typography>
        </Grid>
        {/* End */
        /*Contract Type*/}
        <Grid
          key="1"
          sm={5.8}
          xs={11.9}
          md={3.95}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            textAlign: {
              xs: "center",
              sm: "left",
            },
          }}
        >
          <Typography variant="smallBody" className="text-ellipsis">
            {t("Contract Type")}
          </Typography>

          <Typography
            variant="smallBody"
            sx={{
              opacity: 0.7,
            }}
            fontWeight={400}
            className="text-ellipsis"
          >
            {t(EMPLOYEE_INFO.contractType)}
          </Typography>
        </Grid>
        {/* End */
        /*Joining Date*/}
        <Grid
          key="1"
          sm={5.8}
          xs={11.9}
          md={3.95}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            textAlign: {
              xs: "center",
              sm: "left",
            },
          }}
        >
          <Typography variant="smallBody" className="text-ellipsis">
            {t("Joining Date")}
          </Typography>

          <Typography
            variant="smallBody"
            sx={{
              opacity: 0.7,
            }}
            fontWeight={400}
            className="text-ellipsis"
          >
            {EMPLOYEE_INFO.dateOfJoinig}
          </Typography>
        </Grid>
        {/* End */
        /*Email Address*/}
        <Grid
          key="1"
          sm={5.8}
          xs={11.9}
          md={3.95}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            textAlign: {
              xs: "center",
              sm: "left",
            },
          }}
        >
          <Typography variant="smallBody" className="text-ellipsis">
            {t("Email Address")}
          </Typography>

          <Typography
            variant="smallBody"
            sx={{
              opacity: 0.7,
            }}
            fontWeight={400}
            className="text-ellipsis"
          >
            {EMPLOYEE_INFO.email}
          </Typography>
        </Grid>
        {/* End */
        /*Cost Center*/}
        <Grid
          key="1"
          sm={5.8}
          xs={11.9}
          md={3.95}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            textAlign: {
              xs: "center",
              sm: "left",
            },
          }}
        >
          <Typography variant="smallBody" className="text-ellipsis">
            {t("Cost Center")}
          </Typography>

          <Typography
            variant="smallBody"
            sx={{
              opacity: 0.7,
            }}
            fontWeight={400}
            className="text-ellipsis"
          >
            {EMPLOYEE_INFO.costCenter}
          </Typography>
        </Grid>
        {/* End */
        /*Department*/}
        <Grid
          key="1"
          sm={5.8}
          xs={11.9}
          md={3.95}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            textAlign: {
              xs: "center",
              sm: "left",
            },
          }}
        >
          <Typography variant="smallBody" className="text-ellipsis">
            {t("Department")}
          </Typography>

          <Typography
            variant="smallBody"
            sx={{
              opacity: 0.7,
            }}
            fontWeight={400}
            className="text-ellipsis"
          >
            {EMPLOYEE_INFO.department}
          </Typography>
        </Grid>
        {/* End */}
      </Grid>
    </Box>
  );
}

export default ProfileInfo;
