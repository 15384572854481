import React, { useState, useEffect, useRef } from "react";
import EnhancedTable from "../../Global/Table";
import { CircularChip } from "../../Global/Chips";
import { Box, Typography } from "@mui/material";
import { LevelColor , DistributionColor} from "../../../data";
import AvatarGroupBy from "../../Global/AvatarGroupBy";
import EmployeeInfo from "./EmployeeInfo";
import { useNavigate } from "react-router-dom";
import jwtInterceptor from "../../../services/interceptors";
import { useSnackbar } from "../../Global/WithSnackbar";
import { useTranslation } from "react-i18next";
import SkillDistribution from "./SkillDistribution";

const headCells = [
  {
    id: "skills",
    label: "Skills",
  },
  {
    id: "required",
    label: "Required",
  },
  {
    id: "distribution",
    label: "Distribution",
  },
  {
    id: "members",
    label: "Members",
  },
  {
    id: "percentage",
    label: "Percentage",
  },
];

function createData(
  skills,
  required,
  distribution,
  members,
  percentage,
  Action
) {
  return {
    skills,
    required,
    distribution,
    members,
    percentage,
    Action,
  };
}

function LayeredSkill({ skill, type }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="smallBodyBold">{t(skill)}</Typography>
      <Typography
        variant="smallBody"
        sx={{
          color: "#B3B3BF",
          fontStyle: "italic",
        }}
      >
        {t(type)}
      </Typography>
    </Box>
  );
}

const SkillsDistribution = ({ skills, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "5px",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {skills.lstDistributionDetail.map((skill, i) => {
        const { skillExpertiseId, expertise, count } = skill;
        return (
          <CircularChip
            value={count}
            key={i}
            id={skillExpertiseId}
            color={DistributionColor[expertise]}
            sx={{
              marginLeft: "0px",
            }}
            onClick={() =>
              onClick(skillExpertiseId, skills.skillConfigurationId)
            }
          />
        );
      })}
    </Box>
  );
};

function ManagerSkillManagement() {
  const [userModal, setUserModal] = useState(false);
  const [distributionModal, setDistributionModal] = useState(false);

  const [managerskillsData, setManagerSkillsDataState] = useState([]);
  const [allskillsData, setallskillsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const initialized = useRef(false);
  const bearerToken = sessionStorage.getItem("token_key");
  const email = sessionStorage.getItem("email_key");
  const [selectedSkillId, setSelectedSkillId] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [skillId, setskillId] = useState(null);
  const [SkillDistributionList, setSkillDistribution] = useState(null);

  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  const handleUserClick = (skillConfigurationId, allSkills) => (index) => {
    const skill = allSkills.find(
      (item) => item.skillConfigurationId === skillConfigurationId
    );
    handelUserClick(skill.lstSkillEmployeeDetail[index].employeeDetailId);
  };

  const GetmanagerSkillsListData = async () => {
    setLoading(true);
    const tblRows = [];

    jwtInterceptor
      .get("api/SkillManager/GetSkillDashboardForManager")
      .then((response) => {
        for (var x of response.data) {
          //console.log("Skills data", x);
          setallskillsData(response.data);
          let profilePictures = [];
          for (var i of x.lstSkillEmployeeDetail) {
            let pictureURI =
              process.env.REACT_APP_API_PROFILE_SERVICE_URL +
              "/api/Employee/GetProfilePictureFileStream?EmployeeDetailId=" +
              i.employeeDetailId+"&email="+email;
            profilePictures.push(pictureURI);
          }

          console.log("profilePictures", profilePictures);
          tblRows.push(
            createData(
              <LayeredSkill skill={x.skill} type={x.skillType} />,
              <CircularChip value={x.requiredScore} color="#18A0FB" />,
              <SkillsDistribution skills={x} onClick={handleUserImageClick} />,
              <AvatarGroupBy
                images={profilePictures}
                // eslint-disable-next-line no-loop-func
                onClick={handleUserClick(x.skillConfigurationId, response.data)}
              />,
              x.percentage
            )
          );
        }
        setManagerSkillsDataState(tblRows);
        setLoading(false);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const GetDistributionSkillsListData = async (id, skillId) => {
    setLoading(true);
    const tblRows = [];

    jwtInterceptor
      .get(
        "api/SkillManager/GetSkillDetailBySkillConfigurationId?SkillExpertise=" +
          id +
          "&SkillConfigurationId=" +
          skillId +
          ""
      )
      .then((response) => {
        setSkillDistribution(response.data);
        setDistributionModal(true);
        setLoading(false);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const handleUserImageClick = (id, skillid) => {
    setSelectedSkillId(id);
    setskillId(skillid);

    GetDistributionSkillsListData(id, skillid);
  };

  const handelUserClick = (empId) => {
    setDistributionModal(false);
    setUserModal(true);
    setSelectedEmployee(empId);
  };
  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        GetmanagerSkillsListData();
      } else {
        window.location.href = "/login";
      }
    }
  }, []);

  useEffect(() => {
    console.log("allSkillData", allskillsData);
  }, [allskillsData]);

  return (
    <>
      <EnhancedTable
        head={headCells}
        rows={managerskillsData}
        loading={loading}
      />

      {selectedEmployee && (
        <EmployeeInfo
          open={userModal}
          setOpen={setUserModal}
          id={selectedEmployee}
        />
      )}

      <SkillDistribution
        open={distributionModal}
        setOpen={setDistributionModal}
        handelUserClick={handelUserClick}
        state={SkillDistributionList}
        // skill={allskillsData.find((item) => item.skillConfigurationId === skillId)}
      />
    </>
  );
}

export default ManagerSkillManagement;
