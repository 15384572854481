import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  useEffect,
  useReducer,
  useState,
  useRef,
  useContext,
} from "react";
import CountrySelect from "../../../Global/CountryDropdown";
import { Email } from "@mui/icons-material";
import EditAndSave from "../../../Global/EditAndSave";
import PhoneInput from "react-phone-input-2";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  errorHelperText,
  validateProfileData,
} from "../../../../utils/validation";
import { useNavigate } from "react-router-dom";
import jwtInterceptor from "../../../../services/interceptors";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../../Global/WithSnackbar";
import { themeContext } from "../../../../theme";
import Select from "../../../Global/Select";

const PrivateInformation = ({ gap, state, dispatch, disable, lg, xl }) => {
  const hasError = (field) => {
    return state.error.includes(field);
  };

  const { myTheme } = useContext(themeContext);
  const { t, i18n } = useTranslation();

  console.log("TEST state", state);

  return (
    <Grid
      item
      className="section-border"
      sm={12}
      md={5.9}
      lg={lg}
      xl={xl}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.common.border}`,
      })}
    >
      <Typography variant="LargeBody">{t("Private information")}</Typography>
      <Grid container mt="21px" gap="20px">
        <Grid item xs={12} sm={5.75}>
          <Typography variant="SmallBody">Address</Typography>
          <TextField
            disabled={disable}
            value={state.employeeAddressDto.address}
            variant="outlined"
            placeholder={t("Enter address")}
            onChange={(e) =>
              dispatch({
                type: "sharedInformation",
                field: "address",
                value: e.target.value,
              })
            }
            helperText={hasError("address") && t("Address is required")}
            error={hasError("address")}
          />
        </Grid>
        <Grid item xs={12} sm={5.75}>
          <Typography variant="SmallBody">{t("City")}</Typography>
          <TextField
            variant="outlined"
            name="city"
            disabled={disable}
            placeholder={t("Enter City")}
            value={state.employeeAddressDto.city}
            onChange={(e) =>
              dispatch({
                type: "sharedInformation",
                field: "city",
                value: e.target.value,
              })
            }
            helperText={hasError("city") && t("City is required")}
            error={hasError("city")}
          />
        </Grid>
        <Grid item xs={12} sm={5.75}>
          <Typography variant="SmallBody">{t("Postal Code")}</Typography>
          <TextField
            variant="outlined"
            name="postCode"
            placeholder={t("Enter Postal Code")}
            disabled={disable}
            value={state.employeeAddressDto.postCode}
            onChange={(e) =>
              dispatch({
                type: "sharedInformation",
                field: "postCode",
                value: e.target.value,
              })
            }
            helperText={hasError("postCode") && t("Postal Code is required")}
            error={hasError("postCode")}
          />
        </Grid>
        <Grid item xs={12} sm={5.75}>
          <Typography variant="SmallBody">{t("Country")}</Typography>
          <CountrySelect
            dispatch={dispatch}
            value={state.employeeAddressDto.country}
            disabled={disable}
            helperText={hasError("country") && t("Country is required")}
            error={hasError("country")}
          />
        </Grid>

        <Grid item xs={12} sm={5.75}>
          <Typography variant="SmallBody">
            {t("Social security number")}
          </Typography>
          <TextField
            disabled={disable}
            variant="outlined"
            name="socialSecurityNumber"
            placeholder={t("Enter social security number")}
            value={state.socialSecurityNumber}
            onChange={(e) =>
              dispatch({
                type: "privateInformation",
                field: "socialSecurityNumber",
                value: e.target.value,
              })
            }
            helperText={
              hasError("socialSecurityNumber") &&
              t("Social security number is required")
            }
            error={hasError("socialSecurityNumber")}
          />
        </Grid>
        <Grid item xs={12} sm={5.75}>
          <Typography variant="SmallBody">
            {t("Personal email address")}
          </Typography>
          <TextField
            disabled={disable}
            variant="outlined"
            name="personalEmailId"
            placeholder={t("Enter email address")}
            type="email"
            value={state.personalEmailId}
            onChange={(e) =>
              dispatch({
                type: "privateInformation",
                field: "personalEmailId",
                value: e.target.value,
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email color="error" fontSize="10px" />
                </InputAdornment>
              ),
            }}
            helperText={
              hasError("personalEmailId") && t("Email address is required")
            }
            error={hasError("personalEmailId")}
          />
        </Grid>
        <Grid item xs={12} sm={5.75}>
          <Typography variant="SmallBody">{t("Phone Number")}</Typography>
          <PhoneInput
            disabled={disable}
            placeholder={t("Enter phone number")}
            value={state.personalMobileNumber}
            onChange={(phone) =>
              dispatch({
                type: "privateInformation",
                field: "personalMobileNumber",
                value: phone,
              })
            }
            country={"ca"}
            containerClass={
              myTheme.name + (hasError("phoneNumber") ? "error" : "")
            }
          />
          {errorHelperText(
            hasError("personalMobileNumber") && t("Phone number is required")
          )}
        </Grid>
        <Grid item xs={12} sm={5.75}>
          <Typography variant="SmallBody">{t("Date of birth")}</Typography>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n.language}
          >
            <DatePicker
              disabled={disable}
              disableOpenPicker={disable}
              name="dateOfBirth"
              value={dayjs(state.dateOfBirth)}
              format="DD/MM/YYYY"
              onChange={(e) =>
                dispatch({
                  type: "privateInformation",
                  field: "dateOfBirth",
                  value: e.$d.toISOString(),
                })
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={5.75}>
          <Typography variant="SmallBody">{t("Language")}</Typography>
          <Select
            variant="outlined"
            placeholder={t("Select Language")}
            name={t("preferred Language")}
            value={state.preferedLanguage}
            onChange={(e) =>
              dispatch({
                type: "privateInformation",
                field: "preferedLanguage",
                value: e.target.value,
              })
            }
            disabled={disable}
            error={hasError(state.preferedLanguage)}
            className="error"
          >
            <MenuItem value="English">English</MenuItem>
            <MenuItem value="French">French</MenuItem>
            <MenuItem value="Spanish">Spanish</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SharedInformation = ({ gap, state, dispatch, disable, lg, xl }) => {
  const hasError = (field = "") => {
    return state.error.includes(field);
  };

  const { myTheme } = useContext(themeContext);

  const { t } = useTranslation();

  return (
    <Grid
      item
      className="section-border"
      sm={12}
      md={4.8}
      lg={lg}
      xl={xl}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.common.border}`,
      })}
    >
      <Typography variant="LargeBody">{t("Shared information")}</Typography>
      <Grid container mt="21px" rowGap="20px">
        <Grid item xs={12}>
          <Typography variant="SmallBody">{t("Gender")}</Typography>
          <Select
            variant="outlined"
            placeholder={t("Select Gender")}
            name="gender"
            value={t(state.gender)}
            onChange={(e) =>
              dispatch({
                type: "privateInformation",
                field: "gender",
                value: e.target.value,
              })
            }
            disabled={disable}
            error={hasError(state.gender)}
            className="error"
          >
            <MenuItem value="Male">{t("Male")}</MenuItem>
            <MenuItem value="Female">{t("Female")}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="SmallBody">
            {t("Business Mobile Number")}
          </Typography>
          <PhoneInput
            disabled={disable}
            placeholder={t("Enter phone number")}
            value={state.businessMobileNumber}
            onChange={(e) =>
              dispatch({
                type: "privateInformation",
                field: "businessMobileNumber",
                value: e,
              })
            }
            country={"ca"}
            containerClass={
              myTheme.name + (hasError("phoneNumber") ? "error" : "")
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="SmallBody">
            {t("Office Phone Number")}
          </Typography>
          <PhoneInput
            disabled={disable}
            placeholder={t("Enter phone number")}
            value={state.businessPhoneNumber}
            onChange={(e) =>
              dispatch({
                type: "privateInformation",
                field: "businessPhoneNumber",
                value: e,
              })
            }
            country={"ca"}
            containerClass={
              myTheme.name + (hasError("phoneNumber") ? "error" : "")
            }
          />
          {errorHelperText(
            hasError("businessPhoneNumber") && t("Phone number is required")
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="SmallBody">{t("Site")}</Typography>
          <Select
            variant="outlined"
            name="workPlace"
            placeholder={t("Select Site")}
            disabled={disable}
            value={state.workPlace}
            onChange={(e) =>
              dispatch({
                type: "privateInformation",
                field: "workPlace",
                value: e.target.value,
              })
            }
            error={hasError("workPlace")}
          >
            <MenuItem value="site 1">{t("site 1")}</MenuItem>
            <MenuItem value="site 2">{t("site 2")}</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

const EmergencyContact = ({ gap, state, dispatch, disable, error, lg, xl }) => {
  const hasError = (field) => {
    return error.includes(field);
  };

  const { myTheme } = useContext(themeContext);

  const { t } = useTranslation();

  return (
    <Grid
      item
      className="section-border"
      sm={12}
      md={12}
      lg={lg}
      xl={xl}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.common.border}`,
      })}
    >
      <Typography variant="LargeBody">{t("Emergency contact")}</Typography>
      <Grid container mt="21px" rowGap="20px">
        <Grid item xs={12}>
          <Typography variant="SmallBody">{t("First Name")}</Typography>
          <TextField
            variant="outlined"
            name="firstName"
            placeholder={t("Enter First Name")}
            value={state.firstName}
            onChange={(e) =>
              dispatch({
                type: "emergencyContact",
                field: "firstName",
                value: e.target.value,
              })
            }
            disabled={disable}
            error={hasError("firstName")}
            helperText={hasError("firstName") && t("First Name is required")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="SmallBody">{t("Last Name")}</Typography>
          <TextField
            variant="outlined"
            name="lastName"
            placeholder={t("Enter Last Name")}
            value={state.lastName}
            onChange={(e) =>
              dispatch({
                type: "emergencyContact",
                field: "lastName",
                value: e.target.value,
              })
            }
            disabled={disable}
            error={hasError("lastName")}
            helperText={hasError("lastName") && t("Last Name is required")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="SmallBody">{t("Phone number")}</Typography>
          <PhoneInput
            placeholder={t("Enter phone number")}
            disabled={disable}
            value={state.phoneNumber}
            onChange={(e) =>
              dispatch({
                type: "emergencyContact",
                field: "phoneNumber",
                value: e,
              })
            }
            country={"ca"}
            containerClass={
              myTheme.name + (hasError("phoneNumber") ? "error" : "")
            }
          />
          {errorHelperText(
            hasError("phoneNumber") && t("Phone number is required")
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="SmallBody">{t("Email Address")}</Typography>
          <TextField
            disabled={disable}
            variant="outlined"
            name="email"
            placeholder={t("Enter email address")}
            value={state.email}
            onChange={(e) =>
              dispatch({
                type: "emergencyContact",
                field: "email",
                value: e.target.value,
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email color="error" fontSize="10px" />
                </InputAdornment>
              ),
            }}
            error={hasError("email")}
            helperText={hasError("email") && t("Email address is required")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const initialState = {
  userDetails: {
    employeeDetailId: 4,
    socialSecurityNumber: "",
    personalEmailId: "",
    personalMobileNumber: "",
    dateOfBirth: "",
    preferedLanguage: "English",
    employeeAddressDto: {
      address: "",
      postCode: "",
      city: "",
      country: "",
    },
    gender: "",
    businessPhoneNumber: "",
    businessMobileNumber: "",
    workPlace: "site 1",
    emergencyContactDto: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
    },
    error: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "privateInformation":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "sharedInformation":
      return {
        ...state,
        employeeAddressDto: {
          ...state.employeeAddressDto,
          [action.field]: action.value,
        },
      };
    case "emergencyContact":
      return {
        ...state,
        emergencyContactDto: {
          ...state.emergencyContactDto,
          [action.field]: action.value,
        },
      };
    case "serviceData":
      return {
        ...state,
        ...action.value,
      };
    case "error":
      return {
        ...state,
        error: action.value,
      };
    case "reset":
      const oldData = action.data ?? state;
      return {
        error: [],
        ...oldData,
      };
    default:
      return state;
  }
};

function Profile() {
  const navigate = useNavigate();
  const initialized = useRef(false);
  const [state, dispatch] = useReducer(reducer, initialState.userDetails);
  const [tempState, setTempState] = useState({});
  const { showMessage } = useSnackbar();

  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  const updateProfileData = async () => {
    const {
      error,
      message,
      name,
      code,
      config,
      request,
      response,
      ...restState
    } = state;

    const errorsFound = validateProfileData(restState);

    console.log("error", errorsFound);

    // Additional validation for other fields if needed
    if (errorsFound.length > 0) {
      // Dispatch the error message to the reducer
      dispatch({
        type: "error",
        value: errorsFound,
      });
      return; // Stop updating the profile data if there are errors
    }

    // Clear any existing errors
    dispatch({
      type: "error",
      value: [],
    });

    let url = "api/Employee/UpdateProfileTabDetail?id=" + empId;
    /*let responseReturn = await profileService.updateProfileDataRequest(
      url,
      state,
      bearerToken
    );*/

    jwtInterceptor.post(url, state).then((response) => {
      showMessage(response.data, "success");
    });

    //if (responseReturn.status !== 200) navigate("/login", { replace: true });

    setEdit(false);
  };

  const getProfilePrivateData = async () => {
    jwtInterceptor
      .get("api/Employee/GetProfileBottomDetail?id=" + empId)
      .then((response) => {
        dispatch({
          type: "serviceData",
          field: "",
          value: response.data,
        });
        setTempState(response.data);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        getProfilePrivateData();
      } else {
        navigate("/login", { replace: true });
      }
    }
  }, []);

  const [edit, setEdit] = useState(false);
  if (Object.keys(state).length <= 0) return <span>loading</span>;

  return (
    <Box>
      <EditAndSave
        edit={edit}
        setEdit={setEdit}
        onUpdate={() => updateProfileData()}
        onCancel={() => dispatch({ type: "reset", data: tempState })}
      />
      <Grid container mt={1} columnGap={2} rowGap={2}>
        <PrivateInformation
          xl={5.9}
          lg={5.9}
          state={state}
          dispatch={dispatch}
          disable={!edit}
          error={state.error}
        />
        <SharedInformation
          xl={2.9}
          lg={5.9}
          state={state}
          dispatch={dispatch}
          disable={!edit}
        />
        <EmergencyContact
          xl={2.9}
          lg={12}
          state={state.emergencyContactDto}
          dispatch={dispatch}
          disable={!edit}
          error={state.error}
        />
      </Grid>
    </Box>
  );
}

export default Profile;
