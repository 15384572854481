import React, { useEffect, useState, useRef } from "react";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Center from "../../components/Box/Center";
import useStyles from "./IconStyle";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import jwtInterceptor from "../../services/interceptors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


function Leaves(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    if (props.NOTIFICATIONS.length === 0) {
        return (
            <Box>
                <Typography variant="extraSmallBody">No Notifications</Typography>
            </Box>
        );
    }
    return (
        <>
            {props.NOTIFICATIONS.map((item) => (
                <Box key={item.id}>
                    <Typography variant="extraSmallBody">{t(item.name)}</Typography>
                    <Box>
                        {item.options.map((option) => (
                            <Center mt={2} gap={2}>
                                <InfoTwoToneIcon className={classes.IconStyle} />
                                <Typography variant="body">{t(option.name)}</Typography>
                                <Typography variant="extraSmallBody">
                                    {t(option.time)}
                                </Typography>
                            </Center>
                        ))}
                    </Box>
                </Box>
            ))}
        </>
    );
}

function Notifications(props) {
    const classes = useStyles();
    const bearerToken = sessionStorage.getItem("token_key");
    const empId = sessionStorage.getItem("empId_key");
    const [leaveNotifications, setleaveNotifications] = useState(null);
    const [LeavesNotificationCount, setLeavesNotificationCount] = useState(null);

    const [loading, setLoading] = useState(false);
    const initialized = useRef(false);

    const navigate = useNavigate();
    const GetEmployeeNotifications = async () => {
        setLoading(true);

        jwtInterceptor
            .get(
                "api/EmployeeNotification/GetNotificationListByEmployeeDetailID?EmployeeDetailId=" +
                empId
            )
            .then((response) => {
                console.log("here im ============>>>", response)
                let allLeaves = [];
                for (var x of response.data.leaves) {
                    let item = {
                        id: x.identityId,
                        name: x.date,
                        options: [],
                    };

                    let options = [];

                    for (var option of x.notifications) {
                        let optionItem = {
                            id: option.id,
                            name: option.description,
                            time: "",
                        };
                        options.push(optionItem);
                    }
                    item.options = options;
                    allLeaves.push(item);

                }
                const LeavesData = <Leaves NOTIFICATIONS={allLeaves} />;
                setleaveNotifications(LeavesData);
                setLeavesNotificationCount(allLeaves.length);
            })
            .finally(() => setLoading(false));
    };


    useEffect(() => {
        if (!initialized.current) {
            if (bearerToken) {
                initialized.current = true;
                GetEmployeeNotifications();
            } else {
                window.location.href = "/login";
            }
        }
    }, []);
    const spanStyle = {
        border: '0.5px solid #eb5757',
        backgroundColor: '#eb5757',
        borderRadius: '3px',
        width: '18px',
        height: '18px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 12,
        color: "#fff",
    };


    return (
        <Box flex={1}
            boxShadow={"3.5px 3.5px 5.5px 0px #91919114"}
            p={2.5}
            borderRadius={3}>

            <Box flexDirection="row" alignItems={"center"} sx={{
                display: "flex",
                gap: 1
            }}>
                <Typography variant="h9" fontWeight={600}>
                    Leaves
                </Typography>
                {props.leavesTaken > 0
                    && <div style={spanStyle}>{props.leavesTaken}</div>}
            </Box>
            <Center mt={2} gap={2}>
                {props.leavesTaken > 0 ?
                    <Typography fontWeight={600} mr={"auto"}>
                        {leaveNotifications}
                    </Typography>
                    : <Typography fontWeight={500} mr={"auto"}>
                        No notifications available
                    </Typography>
                }
            </Center>
        </Box>
    );
};
export default Notifications;