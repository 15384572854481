import React, { useEffect, useState, useRef, useContext } from "react";
import { SupportIcon, logo } from "../../../assets/images";
import { Box, ClickAwayListener, useMediaQuery, useTheme, Stack, Typography } from "@mui/material";
import MenuItem from "./MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import jwtInterceptor from "../../../services/interceptors";
import { useSnackbar } from "../../Global/WithSnackbar";
import { themeContext } from "../../../theme";




function Sidebar({ menuItems, favs = [], refetchFavs }) {

  const [menuOpen, setMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(0);

  const theme = useTheme();

  const roles = sessionStorage.getItem("roles") ?? [];
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openTooltip, setOpenTooltip] = useState(0);
  const { myTheme } = useContext(themeContext);

  // get current path
  const { pathname } = useLocation();

  const initialized = useRef(false);
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  useEffect(() => {
    setMenuOpen(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
      } else {
        window.location.href = "/login";
      }
    }
  }, []);

  const onFavCreate = async (fav) => {
    let url = "api/FavouriteLink/AddToFavourite";
    jwtInterceptor.post(url, fav).then((response) => {
      showMessage(response.data, "success");
      refetchFavs();
    });
  };
  const onFavDelete = async (favId) => {
    let url = "api/FavouriteLink/RemoveFromFavourite?FavouriteLinkId=" + favId;
    jwtInterceptor.post(url).then((response) => {
      showMessage(response.data, "success");
      refetchFavs();
    });
  };
  const lineStyle = {
    border: '0.2px solid #EEEE',
    margin: '10px 0',
    width: '100%'
  };

  const handleOpenTooltip = (id) => () => {
    if (isMobile) {
      setOpenTooltip(id);
    }
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(0);
  };

  const handleAddFav = (name, route, e) => {
    e.stopPropagation();
    e.preventDefault();

    let item = {
      favouriteLinkurl: route,
      employeeDetailId: empId,
      title: name,
    };

    if (!favs.some((fav) => fav.name === name)) {
      onFavCreate(item);
    } else {
      let item = favs.filter((fav) => fav.name === name);
      onFavDelete(item[0].id);
    }
  };

  const isFavorite = (name) => {
    return favs.some((fav) => fav.name === name);
  };


  const ColoredBox = ({ color, gradientColor, text, iconColor, iconGradientColor, icon }) => (
    <Box style={{ width: '100%', height: "50px", borderRight: `4px solid ${color}`, marginTop: '5px', display: "flex", justifyContent: "space-between", alignItems: "center", backgroundImage: `linear-gradient(120deg, #2a2d3e11, ${gradientColor})` }}>
      <Typography sx={{ pl: 1, color: theme.palette.grey[400], fontWeight: '500' }}>{text}</Typography>
      <div style={{
        width: "20px",
        backgroundImage: `linear-gradient(90deg, transparent, ${iconGradientColor})`,
        height: "50px",
        marginRight: 2,
        filter: iconColor === "gray" ? "grayscale(100%)" : "",
      }} />
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={handleCloseTooltip}  >
      <Box
        sx={{
          background: (theme) => theme.palette.background.default,
          padding: pathname !== "/" ? "20px" : "",
          "@media (max-width: 600px)": {
            padding: pathname !== "/" ? "10px" : "",
          },
          position: "relative",
        }}
      >

        {pathname !== "/" && (
          <Box mt={1}>
            <Link >
              {menuOpen ? (
                <img
                  src={myTheme.logo}
                  alt="logo"
                  style={{
                    width: "150px",
                    height: "auto",
                  }}
                />
              ) : (
                <img
                  src={myTheme.logoIcon}
                  alt="logo"
                  style={{
                    height: "26px",
                    marginLeft: "5px",
                    width: "fit-content",
                  }}
                />
              )}
              {/* <img
                src={myTheme.logoIcon}
                alt="logo"
                style={{
                  height: "26px",
                  width: "26px",
                  marginLeft: "5px",
                }}
              /> */}
            </Link>
            <hr style={lineStyle} />
          </Box>
        )}

        <Box
          sx={{
            mt: 3,
            height: "calc(100vh - 100px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "start",
            overflow: "auto",
            gap: "30px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "10px",
            }}
          >
            {menuItems.map((option, i) => {
              if (roles.includes(option.role)) {
                return (
                  <MenuItem
                    icon={option.icon}
                    name={option.name}
                    key={option.id}
                    menuOpen={menuOpen}
                    subOptions={option.subOptions}
                    setMenuOpen={setMenuOpen}
                    id={option.id}
                    openSubMenu={openSubMenu}
                    setOpenSubMenu={setOpenSubMenu}
                    //route={option?.subOptions?.length < 1 ? option.route : ""}
                    route={option.route}
                    handleOpenTooltip={handleOpenTooltip}
                    handleCloseTooltip={handleCloseTooltip}
                    openTooltip={openTooltip}
                    isMobile={isMobile}
                    handleAddFavorite={handleAddFav}
                    isFavorite={isFavorite}
                    myTheme={myTheme}
                    disable={option.disable}
                  />
                );
              } else {
                return null;
              }
            })}

            {menuItems.length > 0 && roles.includes("Admin") && (
              <MenuItem
                icon={SupportIcon}
                name="Settings"
                menuOpen={menuOpen}
                id="-1"
                route={"/admin-settings"}
                handleOpenTooltip={handleOpenTooltip}
                handleCloseTooltip={handleCloseTooltip}
                openTooltip={openTooltip}
                isMobile={isMobile}
                handleAddFavorite={handleAddFav}
                isFavorite={isFavorite}
                myTheme={myTheme}
              />
            )}
            <hr style={lineStyle} />


          </Box>
          {
            menuOpen &&
            <Box style={{ marginTop: 0, width: "100%", display: pathname === "/" ? "none" : "block" }} >
              <ColoredBox color="#964CF5" gradientColor="#ffffffa0" text="Business Management" iconColor="normal" iconGradientColor="#954cf576" />
              <ColoredBox color="#37D310" gradientColor="#ffffffa0" text="QHSE" iconColor="normal" iconGradientColor="#37D31076" />
              <ColoredBox color="#E2B93B" gradientColor="#ffffffa0" text="CRM" iconColor="normal" iconGradientColor="#E2B93B76" />
            </Box>
          }


        </Box>
      </Box>
    </ClickAwayListener>
  );
}

export default Sidebar;
