import React, { useEffect, useState } from "react";
import BaseModal from "../Global/Modal";
import { Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const initialState = {
  department: "",
  costCenter: "",
  id: null,
};

function AddNewDepartment({
  open,
  handleClose,
  handleSave,
  department,
  title,
}) {
  console.log(department);
  const [newDapartment, setNewDapartment] = useState(
    !!department?.department ?? initialState
  );
  const [error, seterror] = useState({
    department: false,
    costCenter: false,
  });

  useEffect(() => {
    if (!department?.department) return;
    setNewDapartment(department);
  }, [department]);

  const handleChange = (e) => {
    setNewDapartment({ ...newDapartment, [e.target.name]: e.target.value });
  };

  const onSave = () => {
    let errorCol = {
      department:
        !newDapartment.department || newDapartment.department.trim() === "",
      costCenter:
        !newDapartment.costCenter || newDapartment.costCenter.trim() === "",
    };

    seterror(errorCol);

    if (!errorCol.costCenter && !errorCol.department) {
      handleSave(newDapartment);
      handleClose();
    }

    setNewDapartment(initialState);
  };

  const onCancel = () => {
    setNewDapartment(initialState);
    handleClose();
  };

  const { t } = useTranslation();

  return (
    <BaseModal title={title} handleClose={onCancel} onSave={onSave} open={open}>
      <Stack direction="column" spacing={2}>
        <div>
          <Typography variant="SmallBody">{t("Department")}</Typography>
          <TextField
            variant="outlined"
            name="department"
            placeholder={t("Enter Department")}
            error={error.department}
            helperText={error.department && t("Please enter valid department")}
            onChange={handleChange}
            value={newDapartment.department}
          />
        </div>
        <div>
          <Typography variant="SmallBody">{t("Cost centre")}</Typography>
          <TextField
            variant="outlined"
            name="costCenter"
            placeholder={t("Enter Cost centre")}
            error={error.costCenter}
            helperText={error.costCenter && t("Please enter valid cost centre")}
            onChange={handleChange}
            value={newDapartment.costCenter}
          />
        </div>
      </Stack>
    </BaseModal>
  );
}

export default AddNewDepartment;
