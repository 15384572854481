import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { Add } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactDOMServer from "react-dom/server";
import { useTheme } from "@mui/material/styles";
function descendingComparator(a, b, orderBy) {
  let aValue = a[orderBy];
  let bValue = b[orderBy];

  try {
    if (typeof aValue !== "string" && typeof aValue !== "number") {
      const aString = ReactDOMServer.renderToString(aValue);
      aValue = extractValueFromRenderedString(aString); // extractValue is your method to get value from string
    }

    if (typeof bValue !== "string" && typeof bValue !== "number") {
      const bString = ReactDOMServer.renderToString(bValue);
      bValue = extractValueFromRenderedString(bString);
    }
    if (bValue < aValue) return -1;
    if (bValue > aValue) return 1;
    return 0;
  } catch (error) {
    return 0;
  }
}

function extractValueFromRenderedString(renderedString) {
  const regex = />([^<]+)</;
  const match = renderedString.match(regex);
  const retVal = match ? match[1].trim() : null;

  return retVal;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.label === "Action" || headCell.label === "Actions"
                ? "center"
                : "left"
            }
            sx={{
              fontSize: 14,
              fontWeight: 400
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label === "Action" || headCell.label === "Actions" ? (
              t(headCell.label) // Directly display the label if it's "Action"
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? t("sorted descending")
                      : t("sorted ascending")}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, isAddable, onAddClick, title, btnTitle } = props;
  const { t } = useTranslation();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {title &&
        (numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} {t("selected")}
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle" component="div">
            {t(props.title)}
          </Typography>
        ))}

      {numSelected > 0 && (
        <Tooltip title={t("Delete")}>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      <Box
        sx={{
          ml: "auto",
          minWidth: "120px",
        }}
      >
        {isAddable && (
          <Button
            variant="outlined"
            sx={{
              padding: "10px",
              minWidth: "180px",

              "@media (max-width: 600px)": {
                minWidth: "auto",
              },
            }}
            onClick={onAddClick}
          >
            <Add />
            {btnTitle || t("Add New")}
          </Button>
        )}
      </Box>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  title,
  head,
  rows,
  isAddable = false,
  onAddClick,
  sx = {},
  hidePagination = false,
  loading = false,
}) {

  const theme = useTheme();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(
    head.find((item) => item.isDefaultSort)?.id || head[0].id
  );
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { t } = useTranslation();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rows, rowsPerPage]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          boxShadow: "none",
          background: "transparent",
        }}
      >

        {(isAddable || title) && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            title={t(title)}
            isAddable={isAddable}
            onAddClick={onAddClick}
            btnTitle={t("Add new")}
          />
        )}
        <TableContainer>
          <Table
            sx={{ minWidth: 750, ...sx }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              rowCount={rows.length}
              headCells={head}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.length > 0 ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.name}-${index}`}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      {Object.keys(row).map((key, index) => {
                        if (key === "id") return null;
                        return (
                          <TableCell
                            key={index}
                            sx={{ fontWeight:  "500", fontSize: 12, }}
                            align={key === "action" ? "center" : "left"}
                          >
                            {row[key]} 
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={head.length} align="center">
                    {loading ? <CircularProgress /> : t("No data found")}
                  </TableCell>
                </TableRow>
              )}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
         {!hidePagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}  
      </Paper>
    </Box>
  );
}
