import { Box, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import StatBox from "../../components/Global/StatBox";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import VerifiedIcon from "@mui/icons-material/Verified";
import SkillsTable from "../../components/Profile/SkillManagement/SkillsTable";
import SkillsService from "../../services/skillsManagementService";
import jwtInterceptor from "../../services/interceptors";
import { useSnackbar } from "../../components/Global/WithSnackbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const styles = {
  statsContainer: {
    display: "flex",
    gap: "0px",
    flexWrap: "wrap",
    marginTop: "18px",
    justifyContent: "flex-start"
  },
};

function SkillsManagement() {
  const { t } = useTranslation();

  const [skillKPIs, setskillKPIsState] = useState([]);
  const navigate = useNavigate();
  const initialized = useRef(false);

  const [loading, setLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const service = new SkillsService();

  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  const getData = async () => {
    setLoading(true);

    jwtInterceptor
      .get("api/EmployeeSkill/SkillTopSectionDetail?EmployeeDetailId=" + empId)
      .then((response) => {
        setskillKPIsState(response.data);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));

    //const data = await service.GetSkillListDataRequest();
    //console.log(data);
  };

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        getData();
      } else {
        window.location.href = "/login";
      }
    }
  }, []);

  return (
    <Box
      sx={{
        p: "20px",
        marginTop: "40px",
        position: "relative",

        "@media (max-width: 600px)": {
          p: "10px",
          marginTop: "20px",
        },
      }}
    >
      <Typography variant="h6">{t("Skill Management")}</Typography>
      <Box sx={styles.statsContainer}>
        <StatBox
          title={t("Total of skills registered")}
          value={skillKPIs?.totalSkillRegistered}
          icon={<EmojiEventsIcon />}
          color="#18A0FB"
          hideProgress={true}
        />
        <StatBox
          title={t("Certificates pending renewal")}
          value={skillKPIs?.renewalPending}
          icon={<RestartAltIcon />}
          color="#E01C63"
          hideProgress={true}

        />
        {/* <StatBox
          title={t("Number of recognition received")}
          value="0"
          icon={<WorkspacePremiumIcon />}
          color="#964CF5"
          hideProgress={true}
        /> */}
        <StatBox
          title={t("New skills register")}
          value={skillKPIs?.newSkillRegistered}
          icon={<VerifiedIcon />}
          color="#9DB604"
          hideProgress={true}

        />
      </Box>
      <SkillsTable />
    </Box>
  );
}

export default SkillsManagement;
