import { Box, Button, LinearProgress, Typography, Stack } from "@mui/material";
import React, { useEffect, useState, useRef, useContext } from "react";
import Center from "../../components/Box/Center";
import { DashboardBackground } from "../../assets/images";
import { customColor } from "../../theme/customColor";
import TextIcon from "../../components/Icon/TextIcon";
import { useTranslation } from "react-i18next";
import jwtInterceptor from "../../services/interceptors";
import { useNavigate } from "react-router-dom";
import LeavesInfo from "../../components/dashboard/LeavesInfo";
import DashboardAnnouncement from "./DashboardAnnouncement";
import { DashboardImg } from "../../assets/images";
import { FaArrowRight } from "react-icons/fa6";
import Notifications from "./Notifications";
import WeeklyCalendar from "../../components/dashboard/WeeklyCalander";
import RenewalCertificate from "./CertificateRenewal";
import DashboardHeader from "./DashboardHeader";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  TypographyFontSize: (theme) => ({
    fontSize: "0.9rem"
  }),
  skillTypographyFontSize: (theme) => ({
    fontSize: "0.7rem"
  }),
};
const EmptySpanStyle = {
  paddingRight: '8px',
};
const EmployeeSpanStyle = {
  fontSize: "0.9rem",
  display: "inline"
};
const SkillCard = ({ id, label, value }) => {
  // eslint-disable-next-line default-case
  switch (id) {
    case "0":
      return (
        <Box
          borderRadius={999}
          top={105}
          left={114}
          position="absolute"
        >
          <Center
            flexDirection="column"
            bgcolor="rgba(251, 148, 27, 0.76)"
            width={100}
            height={100}
            borderRadius={999}
            sx={{ scale: "1.2", margin: 1 }}
          >
            <CircularProgress
              variant="determinate"
              size={110}
              thickness={1}
              value={value} // assuming value is a percentage
              sx={{ color: 'rgba(251, 148, 27, 0.76)' }}
            />
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography color="#fff" variant="h7">
                {value + '%'}
              </Typography>
              <Typography color="#fff">{label}</Typography>
            </Box>
          </Center>
        </Box>
      );
    case "1":
      return (
        <Box
          borderRadius={999}
          top={122}
          left={5}
          position="absolute"
        >
          <Center
            flexDirection="column"
            bgcolor="#2FBFDE"
            width={100}
            height={100}
            borderRadius={999}
            sx={{ scale: "0.9" }}
          >
            <CircularProgress
              variant="determinate"
              size={110}
              thickness={1}
              value={value}
              sx={{ color: '#2FBFDE' }}
            />
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography color="#fff" variant="h7">
                {value + '%'}
              </Typography>
              <Typography color="#fff">{label}</Typography>
            </Box>
          </Center>
        </Box>
      );
    case "2":
      return (
        <Box
          borderRadius={999}
          top={45}
          left={50}
          position="absolute"
        >

          <Center
            flexDirection={"column"}
            bgcolor={"rgba(89, 87, 255, 0.79)"}
            width={100}
            height={100}
            borderRadius={999}
            sx={{ scale: "0.7" }}
            zIndex={11}
          >
            <CircularProgress
              variant="determinate"
              size={110}
              thickness={1}
              value={value}
              sx={{ color: 'rgba(89, 87, 255, 0.79)' }}
            />
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography color="#fff" variant="h7">
                {value + '%'}
              </Typography>
              <Typography color="#fff" sx={(theme) => styles.skillTypographyFontSize(theme)}>{label} </Typography>
            </Box>
          </Center>
        </Box>
      );


  }
};

function Dashboard() {
  const [loading, setLoading] = useState(true);

  const fullName = sessionStorage.getItem("fullname");
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");
  const initialized = useRef(false);
  const navigate = useNavigate();
  const [skillInfos, setskillInfoState] = useState([]);
  const [dashboardDetails, setdashboardDetails] = useState([]);
  const { t } = useTranslation();

  const getSkillInfos = async () => {
    jwtInterceptor
      .get(
        "api/HrDashboard/GetHrDashboardTopSkills"
      )
      .then((response) => {
        let objSkills = [];
        // Sort the array in descending order based on the "percentage" property
        const sortedData = response.data.sort((a, b) => {
          const percentageA = parseFloat(a.percentage);
          const percentageB = parseFloat(b.percentage);
          return percentageB - percentageA;
        });
        const top3Skills = sortedData.slice(0, 3);

        for (let i in top3Skills) {
          objSkills.push({
            id: i,
            label: top3Skills[i].skill,
            value: Math.round(top3Skills[i].percentage.replace('%', '')),
          })
        }
        setskillInfoState(objSkills);
        setLoading(false)
      });
  };

  const getDashboardDetails = async () => {
    jwtInterceptor
      .get(
        "api/Dashboard/GetDashboardByEmployeeDetailId?EmployeeDetailId=" + empId
      )
      .then((response) => {
        setdashboardDetails(response.data);
        console.log(response)
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        getSkillInfos();
        getDashboardDetails();
      } else {
        window.location.href = "/login";
      }
    }
  }, []);

 
  return (
    <Box>
      {loading ? ( // Render loader if data is still loading
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          bgcolor={(theme) => theme.palette.background.backLessOps}
          borderRadius={4}
          sx={{
            alignItems: "flex-start",
            p: 2,
            "@media (max-width: 1260px)": {
              pr: "20px",
            },
          }}
        >
          <Center sx={{
            alignItems: "flex-start",
          }}>
            <Box flex={2} sx={{
              padding: 3
            }}>
              <Typography variant="h5" fontWeight={600}>
                {t("Hi")}, {fullName}
              </Typography>
              <Typography variant="body1" ml={6} mt={2}>
                {t("Welcome to your HR environment")}
              </Typography>
              <Box gap={2} sx={{ display: "flex", }} mt={4}>
                <Box sx={{ flex: 2, width: "100%" }}>
                  <DashboardHeader />
                </Box>
                <Stack position={"relative"} height={350} sx={{ flex: 1 }}>
                  <Typography fontWeight={500} fontSize={15} >Skill Management</Typography>
                  <Center>
                    {skillInfos.map((skill) => (
                      <SkillCard {...skill} key={skill.id} />
                    ))}
                  </Center>
                </Stack>

              </Box>
              <Center sx={{ mt: 4, alignItems: "flex-start" }} mt={3} gap={3}>
                <RenewalCertificate />
                <Box flex={1}>
                  <Typography variant="h8" mb={4} fontWeight={600}>
                    {t("Attendance")}
                  </Typography>
                  <Typography variant="h6" fontWeight={600}>
                    {dashboardDetails.totalEmployees} employees
                    <span style={EmptySpanStyle}> </span>
                    <Typography style={EmployeeSpanStyle} color={(theme) => theme.palette.action.disabled}>Total</Typography>
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={dashboardDetails.totalEmployees}
                    sx={{ borderRadius: 999, height: 10, mt: 2 }}
                  />
                  {/* <Center mt={2} gap={2} sx={{ justifyContent: "flex-start" }}>
                <Box>
                  <Center gap={1}>
                    <Box
                      sx={{
                        height: 3,
                        borderRadius: 999,
                        bgcolor: customColor.primary,
                        width: 22,
                      }}
                    ></Box>

                    <Typography
                      color={customColor.light_text}
                      sx={{ fontWeight: 600, mr: "auto" }}
                    >
                      Clock In
                    </Typography>
                  </Center>

                  <Typography color={customColor.light_text} sx={{ fontWeight: 600, ml: "30px" }}>
                    20 employees
                  </Typography>
                </Box>

                <Box>
                  <Center gap={1}>
                    <Box
                      sx={{
                        height: 3,
                        borderRadius: 999,
                        bgcolor: customColor.primary,
                        width: 22,
                      }}
                    ></Box>

                    <Typography
                      color={customColor.light_text}
                      sx={{ fontWeight: 600, mr: "auto" }}
                    >
                      Absence
                    </Typography>
                  </Center>

                  <Typography color={customColor.light_text} sx={{ fontWeight: 600, ml: "30px" }}>
                    20 employees
                  </Typography>
                </Box>
              </Center> */}
                </Box>
              </Center>
            </Box>
            <Box flex={1}>
              <WeeklyCalendar />
              <LeavesInfo />
              <DashboardAnnouncement />
              <Notifications leavesTaken={dashboardDetails.leavesTaken} />
            </Box>
          </Center>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
