import React from 'react';

const MaleIcon = () => {
  return (
    <svg
      width='10'
      height='26'
      viewBox='0 0 10 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.63553 0.501953C6.34119 0.501953 7.72392 1.88469 7.72392 3.59035C7.72392 5.29602 6.34119 6.67875 4.63553 6.67875C2.92986 6.67875 1.54713 5.29602 1.54713 3.59035C1.54713 1.88469 2.92986 0.501953 4.63553 0.501953ZM6.95182 7.45085H6.40363C5.30769 7.95479 4.01061 7.97655 2.86742 7.45085H2.31923C1.03996 7.45085 0.00292969 8.48787 0.00292969 9.76715V16.33C0.00292969 16.9696 0.521443 17.4881 1.16108 17.4881H1.93318V24.051C1.93318 24.6906 2.45169 25.2091 3.09133 25.2091H6.17973C6.81936 25.2091 7.33787 24.6906 7.33787 24.051V17.4881H8.10997C8.74961 17.4881 9.26812 16.9696 9.26812 16.33V9.76715C9.26812 8.48787 8.2311 7.45085 6.95182 7.45085Z'
        fill='#19C03E'
      />
    </svg>
  );
};

export default MaleIcon;