import React, { useEffect, useState, useRef } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Icon } from "@iconify/react";
import { customColor } from "../../theme/customColor";
import TextIcon from "../../components/Icon/TextIcon";
import Center from "../../components/Box/Center";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../components/Global/WithSnackbar";
import jwtInterceptor from "../../services/interceptors";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import AnnouncementPopup from '../../components/ModalPopUp/AnnouncementPopUp';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function DashboardAnnouncement() {
  const { t } = useTranslation();
  const initialized = useRef(false);
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const [announcement, setannouncement] = useState([]);
  const bearerToken = sessionStorage.getItem("token_key");
  const [currentSlide, setCurrentSlide] = useState(0);

  const [isModalOpen, setModalOpen] = useState(false);
  const [announcementDet, setannouncementDet] = useState(null);
  const [open, setOpen] = useState({
    open: false,
  });

  const openModal = () => {
    setannouncementDet(announcement[currentSlide]);
    setOpen({
      open: true,
    });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getAnnouncementData = async () => {
    jwtInterceptor
      //.get("api/Anouncement/GetActiveAnnouncement")  //commented for testing    
      .get("api/Anouncement/GetAllAnnouncement")
      .then((response) => {
        let objAnnouncements = [];

        for (let i in response.data) {
          const formattedDate = new Date(response.data[i].startDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
          objAnnouncements.push({
            title: response.data[i].title,
            description: response.data[i].title,
            startDate: formattedDate,
          })
        }
        setannouncement(objAnnouncements);
        console.log(objAnnouncements)
      })
      .catch((error) => {
        showMessage(error.message, "error");
      });
  };
  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        getAnnouncementData();
      } else {
        window.location.href = "/login";
      }
    }
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <Box width={"100%"} minHeight={"250px"}>
        <div style={{ display: "flex", justifyContent: "space-between" , padding: "0 10px"}}>
          <Typography variant="h6" fontWeight={600}>
            {t("Announcements")}
          </Typography>

          <div>
            <button className="arrow-right arrow" style={{ border: 0, background: "transparent", padding: "0" }}>
              <KeyboardArrowLeftIcon />
            </button>
            <button className="arrow-left arrow" style={{ border: 0, background: "transparent", padding: "0" }}>
              <KeyboardArrowRightIcon />
            </button>
          </div>
        </div>

        <div >
          <Swiper
            grabCursor={true}
            // centeredSlides={true}
            slidesPerView={"auto"}
            navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
            pagination={false}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            onSlideChange={(swiper) => {
              setCurrentSlide(swiper.realIndex);
            }}
          >

            {announcement.length > 0 &&
              announcement
                .map((item, index) => (
                  <SwiperSlide key={index}  style={{backgroundColor: (theme) => theme.palette.background.backLessOps}}>
                    <div style={{ display: "block", width: "100%", padding: 10 }}>
                      <div style={{ display: "flex", alignItems: "center", gap: 5, }}>
                        <TextIcon
                          icon="ph:chats-circle-fill"
                          color="#E2B93B"
                          size={40}
                          fontSize={22}
                        />
                        <Typography fontWeight={500} fontSize={14} >
                          {item.title}
                        </Typography>
                      </div>

                      <div >
                        <Typography sx={{ display: "flex", justifyContent: "flex-end" }} variant="caption" color={(theme) => theme.palette.action.disabled}>
                          <b>Posted:</b> {" "}{item.startDate}
                        </Typography>

                        <div>
                          <Typography
                            sx={{ lineHeight: "normal", fontSize: '14px', textAlign: "left" }}
                          // mr={"auto"}
                          >
                            {item.description}
                          </Typography>
                          <Button
                            endIcon={<Icon icon="formkit:arrowright" />}
                            sx={{
                              svg: { fontSize: "12px !important" },
                              fontSize: "10px !important",
                              display: "flex",
                              padding: 0
                            }}
                            variant="text" onClick={openModal}
                          >
                            Read More
                          </Button>
                        </div>
                      </div>

                    </div>
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>

        <AnnouncementPopup open={open.open}
          handleClose={() => {
            setOpen({
              open: false,
              id: null,
            });
          }} announcementData={announcementDet} />

      </Box>
    </div>

  );
};
export default DashboardAnnouncement;