

import axios from 'axios';
//import authHeader from './AuthHeader';

const API_URL = process.env.REACT_APP_API_PROFILE_SERVICE_URL;

class loginService {

    async validateLoginUserRequest(url,postData) {
        return await axios({
        method: 'post',
        url: API_URL+url,
        data: postData,
        headers: {
            'Content-Type': 'text/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
    }).then(function (response) {
        return response.data;
        
    }).catch(function (error) {
        return error;
    });
    }

    async authenticatingUserTokenRequest(url,token) {
        return await axios({
        method: 'post',
        url: API_URL+url,
        headers: {
            'Content-Type': 'text/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization':' Bearer '+token,
        },
    }).then(function (response) {
        return response.data;
        
    }).catch(function (error) {
        return error;
    });
    }

    async updateFirsttimePasswordRequest(url,postData,barrerToken) {
        return await axios({
        method: 'post',
        url: API_URL+url,
        data: postData,
        headers: {
            'Content-Type': 'text/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization':' Bearer '+barrerToken,
        },
    }).then(function (response) {
        return response.data;
        
    }).catch(function (error) {
        return error;
    });
    }

    async updateforgetPasswordRequest(url,postData) {
        return await axios({
        method: 'post',
        url: API_URL+url,
        data: postData,
        headers: {
            'Content-Type': 'text/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
    }).then(function (response) {
        return response.data;
        
    }).catch(function (error) {
        return error;
    });
    }

    async getTokenrequest(url,postData) {
        return await axios({
        method: 'get',
        url: API_URL+url,
        data: postData,
        headers: {
            'Content-Type': 'text/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
    }).then(function (response) {
        return response;
        
    }).catch(function (error) {
        return error;
    });
    }

}


export default new loginService();