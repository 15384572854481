import React, { useEffect, useState } from "react";
import BaseModal from "../../Global/Modal";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import BasicTabs from "../../Global/BasicTabs";
import { Box } from "@mui/system";
import EnhancedTable from "../../Global/Table";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import { jwtLeave } from "../../../services/interceptors";
import { useSnackbar } from "../../Global/WithSnackbar";
import { UserImage } from "../../Navigation/Topbar/UserInfo/UserInfo";
import jwtInterceptor from "../../../services/interceptors";

function LeaveManagementModal1({
  open,
  handleClose,
  title,
  onSave,
  data,
  setData,
  leaveDetailId,
  lineManagerId,
  selectedStatus1,
}) {
  const { showMessage } = useSnackbar();

  // const [leaveTypes, setLeaveTypes] = useState([]);
  const [comments, setComments] = useState("");
  const [managerComment, setManagerComment] = useState("");
  const { t } = useTranslation();
  const [leaveDetail, setLeaveDetail] = useState(
    data || {
      firstName: "",
      lastName: "",
      leaveType: "",
      startDate: "",
      endDate: "",
      totalDays: "",
      employeeComments: "",
      managerComment: "",
      leaveStatus: "",
    }
  );

  const [scheduleData, setScheduleData] = useState([]);
  const empId = sessionStorage.getItem("empId_key");
  const email = sessionStorage.getItem("email_key");
  const [profilePictureData, setProfilePictureData] = useState(null);

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
    setManagerComment(event.target.value);
  };

  const getConflictData = async () => {
    try {
      const response = await jwtLeave.get(
        `api/LeaveManager/GetScheduleConflict?LeaveDetailId=${leaveDetailId}&LineManagerId=${empId}`
      );
      setScheduleData(response.data);
    } catch (error) {
      console.error("Error fetching schedule data:", error);
    }
  };

  const getLeaveDetail = async () => {
    let url =
      `api/EmployeeLeave/GetLeaveDetailById?leaveDetailId=` + leaveDetailId;
    jwtLeave
      .post(url)
      .then((response) => {
        if (response.status === 200) {
          response.data.leaveStatus = selectedStatus1;
          setLeaveDetail(response.data);
          if (response.data.managerComment) {
            setComments(response.data.managerComment);
          }
          return response.data;
        } else {
          throw new Error("Failed to fetch leave detail");
        }
      })
      .catch((error) => {
        console.error("Error fetching leave detail:", error);
        throw error;
      });
  };

  useEffect(() => {
    if (leaveDetailId) {
      getLeaveDetail();
      getConflictData();
      setComments(leaveDetail.managerComment);
    }
  }, [leaveDetailId, empId]);

  const validate = (values) => {
    let errors = {
      leaveStatus: false,
    };
    if (!values.leaveStatus || values.leaveStatus === "") {
      errors.leaveStatus = true;
    }

    return errors;
  };

  const rows = scheduleData.map((item) =>
    createData(
      `${item.firstName} ${item.lastName}`,
      new Date(item.startDate).toLocaleDateString("en-GB"),
      new Date(item.endDate).toLocaleDateString("en-GB"),
      item.totalDays,
      `${item.firstName} ${item.lastName}`,
      item.employeeDetailId
    )
  );

  function createData(
    user,
    startDate,
    endDate,
    totalDays,
    name,
    employeeDetailId
  ) {
    return {
      user: (
        <Stack direction="row" gap={2} alignItems="center">
          <UserImage
            userPicture={
              "https://hrmsapicoreappservice.azurewebsites.net/api/Employee/GetProfilePictureFileStream?EmployeeDetailId=" +
              employeeDetailId+"&email="+email
            }
          />
          <Typography variant="smallBody">{name}</Typography>
        </Stack>
      ),

      startDate,
      endDate,
      noOfDays: (
        <Typography color="yellow" fontSize={18}>
          {totalDays}
        </Typography>
      ),
    };
  }
  function handleCancel() {
    handleClose();
    setComments("");
  }
  const [selectedStatus, setSelectedStatus] = useState(null);
  useEffect(() => {
    setSelectedStatus(leaveDetail.leaveStatus);
  }, [leaveDetail.leaveStatus]);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setErrors((prevErrors) => ({
      ...prevErrors,
      leaveStatus: false,
    }));
  };

  const [errors, setErrors] = useState({
    leaveStatus: false,
  });

  const handleSave = () => {
    if (selectedStatus === null || selectedStatus === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        leaveStatus: true,
      }));
      return;
    }

    if (selectedStatus === "Approved") {
      onSave("Approved", leaveDetailId, managerComment);
      showMessage("Leave is Approved successfully.", "success");
    } else if (selectedStatus === "Rejected") {
      onSave("Rejected", leaveDetailId, managerComment);
      showMessage("Leave is Rejected Successfully.", "success");
    }

    handleClose();
    // getLeaveDetail();
  };

  const headCells = [
    {
      id: "firstName lastName",
      label: "User",
    },
    {
      id: "startDate",
      label: "Starting Date",
    },
    {
      id: "endDate",
      label: "End date",
    },
    {
      id: "totalDays",
      label: "No. of days",
    },
  ];
  return (
    <BaseModal
      open={open}
      handleClose={handleCancel}
      title={title}
      onSave={handleSave}
      sx={{
        maxWidth: "fit-content",
      }}
    >
      <BasicTabs
        tabs={["Summary", "Schedule"]}
        tabPanels={[
          <Grid
            container
            spacing={2}
            sx={{
              maxWidth: "620px",
            }}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="SmallBody">{t("First Name")}</Typography>
              <TextField
                variant="outlined"
                value={leaveDetail.firstName}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="SmallBody">{t("Last Name")}</Typography>
              <TextField
                variant="outlined"
                value={leaveDetail.lastName}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="SmallBody">{t("Leave")}</Typography>
              <TextField
                variant="outlined"
                value={leaveDetail.leaveType}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="SmallBodyBold">
                {t("Starting Date")}
              </Typography>
              <Box mt={1}>
                <Typography variant="SmallBody" disabled>
                  {" "}
                  {new Date(leaveDetail.startDate).toLocaleDateString("en-GB")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="SmallBodyBold">{t("End date")}</Typography>
              <Box mt={1}>
                <Typography variant="SmallBody" disabled>
                  {" "}
                  {new Date(leaveDetail.endDate).toLocaleDateString("en-GB")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="SmallBodyBold">
                {t("No. of days")}
              </Typography>
              <Box mt={1}>
                <Typography
                  variant="SmallBody"
                  color="yellow"
                  fontSize={18}
                  disabled
                >
                  {leaveDetail.totalDays}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="SmallBody">
                {t("Employee Comments")}
              </Typography>
              <TextField
                variant="outlined"
                value={leaveDetail.employeeComments}
                disabled
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="SmallBody">{t("Leave Status")}</Typography>
              <Stack direction="row" gap="5px" alignItems="center">
                <FormControlLabel
                  label={t("Reject")}
                  control={
                    <Checkbox
                      color="error"
                      checked={selectedStatus === "Rejected"}
                      onChange={() => handleStatusChange("Rejected")}
                    />
                  }
                />

                <FormControlLabel
                  label={t("Approve")}
                  control={
                    <Checkbox
                      color="success"
                      checked={selectedStatus === "Approved"}
                      onChange={() => handleStatusChange("Approved")}
                    />
                  }
                />
              </Stack>
              {errors.leaveStatus && (
                <Typography variant="caption" color="error">
                  Leave Status is required
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="SmallBody">{t("Comments")}</Typography>
              <TextField
                variant="outlined"
                value={comments}
                fullWidth
                multiline
                rows={3}
                onChange={handleCommentsChange}
              />
            </Grid>
          </Grid>,
          <EnhancedTable head={headCells} rows={rows} />,
        ]}
        customTabPanelSx={{
          maxHeight: "600px",
        }}
      />
    </BaseModal>
  );
}

export default LeaveManagementModal1;
