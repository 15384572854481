import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const languages = ["English", "French"];

export default function LanguageSelector0({ isTablet }) {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n.language === "en" ? "English" : "French"
  );

  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const changeLanguage = (language) => {
    const lng =
      language === "English" ? "en" : language === "Spanish" ? "es" : "fr";
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
    // set language in local storage
  };

  return (
    <FormControl
      sx={{
        minWidth: 120,
        ...(isTablet && {
          width: "100%",
        }),
      }}
      size="small"
    >
      <Select
        value={selectedLanguage}
        onChange={handleChange}
        sx={(theme) => ({
          background: theme.palette.background.default,
          padding: '8px',
          ".Mui-focused": {
            borderBottom: "none",
          },

          fieldset: {
            border: "none",
          },
        })}
        MenuProps={{
          sx: (theme) => ({
            ".MuiPaper-root": {
              // border: `2px solid ${theme.palette.common.border}`,
              borderTop: "none",
              borderRadius: "0px",
              boxShadow: "none",
            },
          }),
        }}
      >
        {languages.map((language) => (
          <MenuItem
            value={language}
            selected={language === selectedLanguage}
            key={language}
            onClick={() => changeLanguage(language)}
          >
            {t(language)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
