import Chart from 'chart.js/auto';
import React, { useEffect, useRef, useState } from 'react';

const ProgressiveChart = ({ summary }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (summary && summary.length > 0) {
      const newData = summary.map((item) => ({
        x: parseInt(item.year),
        y: parseFloat(item.value.replace('%', '')),
      }));
      setChartData(newData);
    }
  }, [summary]);

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      const chart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: [
            {
              borderColor: '#18a0fb',
              borderWidth: 1,
              radius: 0,
              data: chartData,
            },
          ],
        },
        options: {
          animation: {
            duration: 2000,
            easing: 'linear',
          },

          interaction: {
            intersect: false,
          },
          plugins: {
            legend: false,
          },
          scales: {
            x: {
              type: 'linear',
              grid: {
                display: false,
              },

              display: false,
            },

            y: {
              grid: {
                display: false,
              },

              display: false,
            },
          },
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);

  return <canvas ref={chartRef} />;
};

export default ProgressiveChart;
