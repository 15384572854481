import { Box, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { errorHelperText } from "../../utils/validation";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../Global/WithSnackbar";

function DragAndDrop({
  edit = true,
  type = ".pdf, .doc, .docx, .ppt, .pptx, .mp4, .mp3",
  onChangeFile = () => {},
  sx = {},
  allowMultiple = false,
  error = false,
  defaultFileName,
}) {
  const inputRef = useRef();
  const [fileName, setFileName] = useState(defaultFileName);
  const { showMessage } = useSnackbar();

  const isFileUnder20MB = (file) => {
    return file.size / 1024 / 1024 < 20;
  };

  const onDrop = useCallback((acceptedFiles) => {
    const formatedFilesName = acceptedFiles.map((file) => file.name).join(", ");

    for (let i = 0; i < acceptedFiles.length; i++) {
      if (!isFileUnder20MB(acceptedFiles[i])) {
        showMessage("File size should be less than 20MB", "error");
        return;
      }
    }

    setFileName(formatedFilesName);
    onChangeFile({
      target: {
        name: "file",
        files: acceptedFiles,
      },
    });
  }, [onChangeFile, showMessage]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: type,
  });

  const handleChangeFile = (e) => {
    const files = e.target.files;
    const formatedFilesName = Array.from(files)
      .map((file) => file.name)
      .join(", ");
    setFileName(formatedFilesName);
    onChangeFile(e);
  };

  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          border: `1px dashed ${error ? "#FF0000" : "#4959ED"}`,
          borderRadius: "10px",
          width: "100%",
          height: "auto",
          textAlign: "center",
          pt: "20px",
          pb: "20px",
          mt: "21px",
          aspectRatio: "1/1",
          maxWidth: "400px",
          maxHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          ...(!edit && {
            opacity: 0.5,
            pointerEvents: "none",
            cursor: "not-allowed",
          }),
          ...sx,
        }}
        {...getRootProps()}
      >
        <TextSnippetIcon sx={{ fontSize: "50px", color: "#4959ED" }} />
        <input
          type="file"
          ref={inputRef}
          style={{
            appearance: "none",
            display: "none",
          }}
          name="file"
          onChange={handleChangeFile}
          multiple={allowMultiple}
          accept={type}
          {...getInputProps()}
        />
        <Box
          sx={{
            mt: "20px",
          }}
        >
          <Typography
            variant="SmallBody"
            sx={{
              opacity: 0.7,
            }}
          >
            {isDragActive ? (
              <p>
                <strong>{t("Drop")}</strong>
                {t("it like it's hot 🔥")}
              </p>
            ) : (
              <p>{t("Drag and Drop Here")}</p>
            )}
          </Typography>
          <br />
          <Typography
            variant="SmallBody"
            sx={{
              opacity: 0.7,
            }}
          >
            {t("Or")}
          </Typography>
          <br />
          <Typography
            variant="SmallBodyBold"
            sx={{
              color: "#4959ED",
              cursor: "pointer",
            }}
          >
            {t("Browse")}
          </Typography>
          <Typography
            variant="SmallBodyBold"
            sx={{
              fontStyle: "italic",
            }}
            display="block"
          >
            {fileName}
          </Typography>
        </Box>
      </Box>

      {error && errorHelperText(t("Please upload a file"))}
    </>
  );
}

export default DragAndDrop;
