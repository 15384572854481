import React, { useState, useEffect, useRef, useReducer } from "react";
import EnhancedTable from "../../Global/Table";
import { CircularChip, RoundedChip } from "../../Global/Chips";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  ToggleButton,
  Typography,
  alpha,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BaseModal from "../../Global/Modal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DragAndDrop from "../../Global/DragAndDrop";
import DeleteModal from "../../Global/DeleteModal";
import SkillsService from "../../../services/skillsManagementService";
import { useNavigate } from "react-router-dom";
import jwtInterceptor from "../../../services/interceptors";
import { useSnackbar } from "../../Global/WithSnackbar";
import { useTranslation } from "react-i18next";
import Select from "../../Global/Select";

const headCells = [
  {
    id: "skills",
    label: "Skills",
  },
  {
    id: "expertise",
    label: "Expertise",
  },
  {
    id: "achieved",
    label: "Achieved",
  },
  {
    id: "required",
    label: "Required",
  },
  {
    id: "attachment",
    label: "Attachment",
  },
  {
    id: "Renewal date",
    label: "Renewal date",
  },
  {
    id: "Action",
    label: "Action",
  },
];

function createData(
  skills,
  expertise,
  achieved,
  required,
  attachment,
  renewal_date,
  Action
) {
  return {
    skills,
    expertise,
    achieved,
    required,
    attachment,
    renewal_date,
    Action,
  };
}

function LayeredSkill({ skill, type, t }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="smallBodyBold">{t(skill)}</Typography>
      <Typography
        variant="smallBody"
        sx={{
          color: "#B3B3BF",
          fontStyle: "italic",
        }}
      >
        {t(type)}
      </Typography>
    </Box>
  );
}

function CellAction({ onEdit, onDelete }) {
  return (
    <Box className="action-icon-rounded">
      <Button
        sx={{
          backgroundColor: alpha("#DF6F79", 0.1),

          svg: {
            fill: "#DF6F79",
          },
        }}
      >
        <DeleteIcon onClick={onDelete} />
      </Button>
      <Button>
        <MoreVertIcon />
      </Button>
    </Box>
  );
}

function AddAttachment({ url }) {
  if (!url) return " ";

  return (
    <Link
      to={url}
      sx={{
        marginLeft: "9%",
      }}
    >
      <LinkIcon />
    </Link>
  );
}

const score = [
  "Unexperienced",
  "Novice",
  "Advanced beginner",
  "Competent",
  "Proficient",
  "Expert",
];

const initialState = {
  SkillConfigurationId: "",
  EmployeeDetailId: "",
  SkillAchievementId: "",
  SkillExpertiseId: "",
  RenewalDate: "",
  file: "",
};
const reducer = (state, action) => {
  switch (action.type) {
    case "skills":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "serviceData":
      return {
        ...state,
        ...action.value,
      };
    case "error":
      return {
        ...state,
        error: action.value,
      };
    case "reset":
      return {
        ...state,
        error: [],
      };
    default:
      return state;
  }
};
function EmployeeSkillsTable() {
  const service = new SkillsService();
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [Achievement, setAchievement] = useState(0);
  const [btnType, setbtnTypeState] = useState();
  const [id, setIdState] = useState(0);
  const [skillsList, setallSkillsListDataState] = useState([]);
  const [experts, setallExpertsListDataState] = useState([]);
  const [skills, dispatch] = useReducer(reducer, initialState);
  const [file, setFile] = useState(null);
  const initialized = useRef(false);
  const { showMessage } = useSnackbar();

  const tblRows = [];
  const [skillsData, setSkillsDataState] = useState([]);
  const [loading, setLoading] = useState(false);
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");

  const navigate = useNavigate();

  const GetSkillsConfigurationListData = async () => {
    setLoading(true);

    jwtInterceptor
      .get("api/SkillConfiguration/GetSkillConfigurationList")
      .then((response) => {
        let allSkills = [];
        for (var x of response.data) {
          let item = {
            skillConfigurationId: x.skillConfigurationId,
            skill: x.skill,
          };
          allSkills.push(item);
        }

        setallSkillsListDataState(allSkills);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const GetSkillsExpertsConfigurationListData = async () => {
    setLoading(true);

    jwtInterceptor
      .get("api/SkillConfiguration/GetSkillExpertiseList")
      .then((response) => {
        let allExperties = [];
        for (var x of response.data) {
          let item = {
            skillExpertiseId: x.skillExpertiseId,
            expertise: x.expertise,
          };
          allExperties.push(item);
        }

        setallExpertsListDataState(allExperties);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const GetSkillsListData = async () => {
    setLoading(true);

    jwtInterceptor
      .get(
        "api/EmployeeSkill/GetSkillDashboardByEmployeeDetailId?EmployeeDetailId=" +
          empId
      )
      .then((response) => {
        for (var x of response.data) {
          let eId = x.employeeSkillId;
          let item = x;
          tblRows.push(
            createData(
              LayeredSkill({ skill: x.skill, type: x.skillType, t }),
              <RoundedChip status={x.expertise} color="#27AE60" />,
              <CircularChip value={x.skillAchievementId} color="#18A0FB" />,
              <CircularChip value={x.requiredScore} color="#18A0FB" />,
              <AddAttachment url={x.blobFilePath} />,
              x.renewalDate
                ? new Date(x.renewalDate).toLocaleDateString("en-GB")
                : "",
              <CellAction
                id={x.employeeSkillId}
                onEdit={() => {
                  onEdit("Edit", item);
                }}
                onDelete={() => onDelete(eId)}
              />
            )
          );
        }
        setSkillsDataState(tblRows);
        setLoading(false);
      })
      .catch((err) => {
        showMessage(err.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const deleteSkillset = async () => {
    //const response = await service.deleteSkillRequest(id);
    let url = "api/EmployeeSkill/DeleteEmployeeSkills?EmployeeSkillId=" + id;
    jwtInterceptor
      .delete(url)
      .then((response) => {
        showMessage(response.data, "success");
        GetSkillsListData();
      })
      .catch((err) => {
        showMessage(err.message, "error");
      });
  };

  function onEdit(from, item) {
    setOpen((pre) => !pre);
    setbtnTypeState(from);
    setIdState(item.employeeSkillId);
  }
  function onDelete(eId) {
    setDeleteModal((pre) => !pre);
    setIdState(eId);
  }
  function onConfirmationDelete() {
    setDeleteModal((pre) => !pre);
    deleteSkillset();
  }
  const inputChange = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    dispatch({
      type: "skills",
      field: name,
      value: val,
    });
  };
  const inputDateChange = (e) => {
    const val = e.$d.toISOString();
    const name = "RenewalDate";
    dispatch({
      type: "skills",
      field: name,
      value: val,
    });
  };

  const setFileInformation = (file) => {
    setFile(file);
  };
  const onSkillSave = async () => {
    const formData = new FormData();
    console.log(skills);
    formData.append("file", file);
    // formData.append("name", name); filename is not required at any of the place
    formData.append("SkillConfigurationId", skills.SkillConfigurationId);
    formData.append("EmployeeDetailId", empId);
    formData.append("SkillAchievementId", Achievement);
    formData.append("SkillExpertiseId", skills.SkillExpertiseId);
    formData.append("RenewalDate", skills.RenewalDate);

    if (skills.SkillConfigurationId != "" && Achievement) {
      let url = "EmployeeSkill/CreateEmployeeSkills";
      let response = await service.createNewSkillRequest(
        url,
        formData,
        bearerToken
      );
      if (response.status === 200) {
        showMessage(response.data, "success");
        GetSkillsListData();
      } else {
        showMessage(response.data, "error");
      }

      /* jwtInterceptor
        .post(url, formData)
        .then((response) => {
          showMessage(response.data, "success");
          GetSkillsListData();
        })
        .catch((err) => {
          showMessage(err.message, "error");
        });*/

      setOpen((pre) => !pre);
    } else {
      showMessage("Select Skill and Achievement values", "error");
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      if (bearerToken) {
        initialized.current = true;
        GetSkillsListData();
        GetSkillsConfigurationListData();
        GetSkillsExpertsConfigurationListData();
      } else {
        window.location.href = "/login";
      }
    }
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <>
      <EnhancedTable
        head={headCells}
        rows={skillsData}
        isAddable={true}
        onAddClick={() => setOpen((pre) => !pre)}
        loading={loading}
        title={t("Employee Skills")}
      />
      <BaseModal
        open={open}
        handleClose={() => setOpen((pre) => !pre)}
        onSave={() => {
          onSkillSave();
        }}
        title="Skill Management - New Skill"
      >
        <Grid
          container
          spacing="10px"
          sx={{
            overflowX: "hidden",
          }}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody" fontWeight={500}>
              {t("Skill")}
            </Typography>
            <Select
              variant="outlined"
              placeholder={t("Select Skill")}
              name="SkillConfigurationId"
              onChange={(e) => inputChange(e)}
            >
              <MenuItem disabled value="">
                <em>{t("Expertise")}</em>
              </MenuItem>
              {skillsList.map((item, i) => (
                <MenuItem value={item.skillConfigurationId} key={i}>
                  {t(item.skill)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody" fontWeight={500}>
              {t("Expertise")}
            </Typography>
            <Select
              variant="outlined"
              placeholder={t("Select Expertise")}
              name="SkillExpertiseId"
              onChange={(e) => inputChange(e)}
            >
              <MenuItem disabled value="">
                <em>{t("Expertise")}</em>
              </MenuItem>
              {experts.map((item, i) => (
                <MenuItem value={item.skillExpertiseId} key={i}>
                  {t(item.expertise)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody" fontWeight={500}>
              {t("Achievement Score")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                mt: "10px",
              }}
            >
              {[...Array(6)].map((_, num) => (
                <ToggleButton
                  selected={num === Achievement}
                  sx={{
                    color: "black",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                  }}
                  onClick={() => setAchievement(num)}
                >
                  {num}
                </ToggleButton>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody" fontWeight={500}>
              {t("Renewel Date")}
            </Typography>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={i18n.language}
            >
              <DatePicker onChange={inputDateChange} format="DD/MM/YYYY" />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody" fontWeight={500}>
              {t("Score")}
            </Typography>
            <Box
              sx={{
                mt: "10px",
              }}
            >
              {[...Array(6)].map((_, num) => (
                <span
                  key={num}
                  style={{ display: "block", textTransform: "capitalize" }}
                >
                  {num} {t(score[num])}
                </span>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="SmallBody" fontWeight={500}>
              {t("Attachment")}
            </Typography>
            <DragAndDrop
              edit={true}
              onChangeFile={(e) => setFileInformation(e.target.files[0])}
              sx={{
                minHeight: "170px",
              }}
            />
          </Grid>
        </Grid>
      </BaseModal>
      <DeleteModal
        open={deleteModal}
        onCancel={() => setDeleteModal((pre) => !pre)}
        title="Delete Skill"
        description="Are you sure you want to delete this Skill?"
        onConfirm={() => onConfirmationDelete()}
      />
    </>
  );
}

export default EmployeeSkillsTable;
