import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import CircleIcon from "@mui/icons-material/Circle";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import jwtInterceptor from "../../services/interceptors";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material';



const customStyles = {
  width: "100%",
  height: "fit-content",
  ".MuiPickersSlideTransition-root": {
    overflow: "hidden",
  },
  ".MuiDayCalendar-monthContainer": {
    // target each direct child div of the month container
    "& > div": {
      marginBottom: "16px",
    },
  },
  ".MuiDayCalendar-header": {
    justifyContent: "space-around",
    "& > span": {
      color: "#18A0FB",
      fontWeight: 600,
      fontSize: "18px",
    },
  },
  ".MuiDayCalendar-weekContainer": {
    justifyContent: "space-around",

    button: {
      height: "100% !important",
    },
  },
  ".MuiPickersCalendarHeader-labelContainer": {
    color: "#18A0FB",
    fontWeight: 600,
    fontSize: "18px",

    path: {
      fill: "#18A0FB",
    },
  },
  ".MuiButtonBase-root.Mui-selected": {
    color: "#fff",
    padding: "5px",
  },
  ".MuiPickersArrowSwitcher-root": {
    scale: "1.1",

    path: {
      fill: "#18A0FB",
    },
  },
  ".MuiPickersCalendarHeader-root": {
    marginBottom: "24px",
  },
  ".MuiPickersCalendarHeader-label": {
    textTransform: "capitalize",
  },
};
export function getCurrentWeekDates(startingDate = new Date()) {
  const today = new Date(startingDate);
  const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - currentDay); // Set to the first day of the week (Sunday)

  const weekDates = [];
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    weekDates.push({
      date: currentDate,
      day: getDayName(currentDate.getDay()),
    });
  }

  return weekDates;
}

export function getMonthYearString(date) {
  const options = { month: "long", year: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

// Function to get the day name based on the day index
export function getDayName(dayIndex) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[dayIndex];
}

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const toDayHighlight = highlightedDays.find(
    (highlightedDay) => highlightedDay.day === dayjs(day).date()
  );

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={getHighlightContent(
        toDayHighlight?.isHoliday,
        toDayHighlight?.isLeave,
        toDayHighlight?.name
      )}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        sx={{
          fontSize: "18px",
        }}
      />
    </Badge>
  );
}

const BankHoliday = ({ name }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(name)} placement="top">
      <CircleIcon color="error" fontSize="10px" />
    </Tooltip>
  );
};

const LeaveTaken = ({ name }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(name)} placement="top">
      <CircleIcon color="success" fontSize="10px" />
    </Tooltip>
  );
};

const getHighlightContent = (isHoliday, isLeave, name) => {
  if (isHoliday) {
    return <BankHoliday name={name} />;
  }

  if (isLeave) {
    return <LeaveTaken name={name} />;
  }

  return undefined;
};

let daysToHighlight = [];
let allLeaves = [];
function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth();
      /* const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      );*/
      daysToHighlight = [];
      for (var x of allLeaves) {
        if (x.month == date.$M && x.year == date.$y) {
          daysToHighlight.push(x);
        }
      }

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException("aborted", "AbortError"));
    };
  });
}

const initialValue = dayjs(new Date());
export function DateCalendarServerRequest() {
  const [leaves, setLeaves] = useState([])
  const requestAbortController = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);

  const navigate = useNavigate();
  const bearerToken = sessionStorage.getItem("token_key");
  const empId = sessionStorage.getItem("empId_key");
  const {
    i18n: { language },
  } = useTranslation();

  // const lng = localStorage.getItem();

  useEffect(() => {
    if (bearerToken) {
      getMyLeavesData();
    } else {
      window.location.href = "/login";
    }
  }, [bearerToken, language, navigate]);

  const getMyLeavesData = useCallback(async () => {
    setIsLoading(true);
    jwtInterceptor
      .get(
        "api/PublicHoliday/GetAllLeavesAndPublicHolidays?EmployeeDetaailId=" +
        empId
      )
      .then((response) => {
        //setpresentaion(response.data);
        daysToHighlight = [];
        allLeaves = [];
        for (var x of response.data) {
          let item = {
            id: 1,
            day: new Date(x.startDate).getDate(),
            month: new Date(x.startDate).getMonth(),
            year: new Date(x.startDate).getFullYear(),
            isHoliday: x.category == "Holiday" ? true : false,
            isLeave: x.category == "Leave" ? true : false,
            name: x.name,
          };

          allLeaves.push(item);
        }
        for (var x of allLeaves) {
          if (x.month == initialValue.$M && x.year == initialValue.$y) {
            daysToHighlight.push(x);
          }
        }

        setHighlightedDays(daysToHighlight);
        console.log(daysToHighlight)
        setIsLoading(false);
      });
  });

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  const handleMonthChange = (date) => {
    //if (requestAbortController.current) {
    // make sure that you are aborting useless requests
    // because it is possible to switch between months pretty quickly
    //requestAbortController.current.abort();
    //}
    daysToHighlight = [];
    for (var x of allLeaves) {
      if (x.month == date.$M + 1 && x.year == date.$y) {
        daysToHighlight.push(x);
      }
    }

    setHighlightedDays(daysToHighlight);

    setIsLoading(true);
    // setHighlightedDays([]);
    fetchHighlightedDays(date);
    getMyLeavesData();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
      <DateCalendar
        defaultValue={initialValue}
        loading={isLoading}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        sx={customStyles}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
        }}
      />
    </LocalizationProvider>
  );
}

function WeeklyCalendar() {
  const theme = useTheme()
  const [currentWeekDates, setCurrentWeekDates] = useState(
    getCurrentWeekDates()
  );
  const [leaves, setLeaves] = useState([])
  console.log(currentWeekDates)
  const moveToPreviousWeek = () => {
    setCurrentWeekDates((prevWeekDates) => {
      const newStartDate = new Date(prevWeekDates[0].date);
      newStartDate.setDate(newStartDate.getDate() - 7);
      return getCurrentWeekDates(newStartDate);
    });
  };

  const moveToNextWeek = () => {
    setCurrentWeekDates((prevWeekDates) => {
      const newStartDate = new Date(prevWeekDates[0].date);
      newStartDate.setDate(newStartDate.getDate() + 7);
      return getCurrentWeekDates(newStartDate);
    });
  };

  const isCurrentDate = (date) => {
    const today = new Date();
    return date.toDateString() === today.toDateString();
  };
  const empId = sessionStorage.getItem("empId_key");

  // New one
  const getMyLeavesData = async () => {
    // setIsLoading(true);
    jwtInterceptor
      .get(
        "api/PublicHoliday/GetAllLeavesAndPublicHolidays?EmployeeDetaailId=" +
        empId
      )
      .then((response) => {
        console.log(response.data);
        setLeaves(response.data);
        daysToHighlight = [];
        allLeaves = [];
        for (var x of response.data) {
          let item = {
            id: 1,
            day: new Date(x.startDate).getDate(),
            month: new Date(x.startDate).getMonth(),
            year: new Date(x.startDate).getFullYear(),
            isHoliday: x.category == "Holiday" ? true : false,
            isLeave: x.category == "Leave" ? true : false,
            name: x.name,
          };

          allLeaves.push(item);
        }
        for (var x of allLeaves) {
          if (x.month == initialValue.$M && x.year == initialValue.$y) {
            daysToHighlight.push(x);
          }
        }
        console.log(allLeaves)
        // setHighlightedDays(daysToHighlight);
        // setIsLoading(false);
      });
  };

  const checkLeaveForDate = (date) => {
    return leaves.some((leave) => {
      const leaveDate = new Date(leave.startDate);
      console.log(leave.name)
      return date.toDateString() === leaveDate.toDateString();
    });
  };


  const getBadgeColor = (date) => {
    const hasLeave = checkLeaveForDate(date);
    if (hasLeave) {
      const leave = leaves.find((leave) => {
        const leaveDate = new Date(leave.startDate);
        return date.toDateString() === leaveDate.toDateString();
      });
      return leave.category === "Leave" ? "success" : "error";
    }
    return undefined;
  };

  const getTooltipContent = (date) => {
    const leave = leaves.find((leave) => {
      const leaveDate = new Date(leave.startDate);
      return date.toDateString() === leaveDate.toDateString();
    });

    if (leave) {
      return `${leave.name} - ${leave.category}`;
    } 
  };

  useEffect(() => {
    getMyLeavesData()
  }, [])


  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.backLessOps,
        borderRadius: 3,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton onClick={moveToPreviousWeek}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography sx={{
          fontSize: 12,
          fontWeight: "600"
        }}>{getMonthYearString(currentWeekDates[0].date)} </Typography>
        <IconButton onClick={moveToNextWeek}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
      <div style={{ display: "flex", justifyContent: "space-between", padding: 5 }}>
        {currentWeekDates.map((day, index) => (
          <div
            key={index}
            style={{
              textAlign: "center",
              padding: "5px",
              borderRadius: "20px",
              backgroundColor: isCurrentDate(day.date) ? "#132f60" : "inherit",
              color: isCurrentDate(day.date) ? "white" : "inherit",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <div style={{ fontSize: 12, fontWeight: '500' }}>{getDayName(day.date.getDay()).charAt(0)}</div>
            <Box
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: isCurrentDate(day.date)
                  ? theme.palette.primary.main
                  : theme.palette.background.lightBack,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
                fontWeight: '500',
                color: isCurrentDate(day.date)
                  ? theme.palette.text.hover
                  : theme.palette.text.primary,
              }}

            >
              <Tooltip
                title={getTooltipContent(day.date)}
                placement="top"
              >
                <Badge
                  badgeContent=" "
                  color={getBadgeColor(day.date)}
                  variant="dot"
                  invisible={!checkLeaveForDate(day.date)}
                >
                  {day.date.getDate()}
                </Badge>
              </Tooltip>
            </Box>
          </div>
        ))}
      </div>
    </Box>
  );
}

export default WeeklyCalendar;
