import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper";
import GetImage from "./GetImage";
import { useTranslation } from "react-i18next";

function Card({ data, selectPresentation }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      sx={{
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        // width: "400px",
        width: "100%",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },

        "&:hover": {
          backgroundColor: (theme) => theme.palette.background.backLessOps,
        },
      }}
      onClick={() => selectPresentation(data.id)}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "flex-start",
          },
        }}
      >
        <Box
          sx={{
            width: "180px",
            height: "100px",
            overflow: "hidden",

            [theme.breakpoints.down("sm")]: {
              width: "80%",
            },
            border: (theme) => `1px solid ${theme.palette.common.border}`,
          }}
        >
          <GetImage
            presentation={data.presentations[0]}
            styles={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Stack
          direction="column"
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <Typography variant="h5" sx={{fontSize: 16}}>{data.title}</Typography>
          <Typography variant="smallBody" >
            <strong>{t("Posted")}:</strong>
            {data.posted}
          </Typography>
          <Typography variant="smallBody">
            <strong>{t("Format")}:</strong>

            {data.format}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default function CardsSlide({ presentations = [], selectPresentation }) {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        my: 4,
        ".swiper-slide": {
          height: "fit-content",
        },
        width: "100%",
      }}
    >
      <Swiper
        navigation={false}
        modules={[Navigation]}
        className="mySwiper"
        slidesPerView={isTablet ? 1 : 3}
        spaceBetween={0}
      >
        {presentations.length > 0 &&
          presentations.map((item, index) => (
            <SwiperSlide
              key={index}
              style={{
                background: "transparent",
              }}
            >
              <Card data={item} selectPresentation={selectPresentation} />
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  );
}
